import React, { useEffect, useState } from "react";
import {
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// ✅ Styled Components Matching TopMinersTable
const StyledCard = styled(Card)({
  backgroundColor: "#1b1f23",
  color: "white",
  borderRadius: "8px",
  boxShadow: "0px 3px 10px rgba(255, 255, 255, 0.1)",
  padding: "10px",
  minWidth: "260px",
});

const StyledTableContainer = styled(TableContainer)({
  backgroundColor: "#25282f",
  borderRadius: "8px",
  boxShadow: "none",
  maxHeight: "360px",
  overflowY: "auto",
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: "#2d323a",
});

const StyledTableCell = styled(TableCell)({
  color: "white",
  borderBottom: "1px solid #444",
  textAlign: "center",
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: "13px",
  padding: "6px 8px",
});

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": { backgroundColor: "#2a2e35" },
  "&:nth-of-type(even)": { backgroundColor: "#202329" },
});

const PaymentsTable = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch("https://sedrapool.com/api/pools/sdr/payments?page=0&count=20");
        if (!response.ok) throw new Error("Failed to load payments");
        const data = await response.json();
        setPayments(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchPayments();
  }, []);

  return (
    <StyledCard>
      <CardContent sx={{ padding: "10px" }}>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
            fontFamily: "'Russo One', sans-serif",
            textTransform: "uppercase",
            textAlign: "left",
          }}
        >
          Recent Pool Payouts
        </Typography>

        {loading ? (
          <CircularProgress sx={{ color: "white", marginTop: "10px" }} size={18} />
        ) : error ? (
          <Typography color="error" sx={{ fontSize: "12px" }}>{error}</Typography>
        ) : (
          <StyledTableContainer component={Paper}>
            <Table size="small" sx={{ minWidth: 550 }}>
              <StyledTableHead>
                <TableRow>
                  <StyledTableCell>Time</StyledTableCell>
                  <StyledTableCell>Address</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {payments.map((row, idx) => (
                  <StyledTableRow key={idx}>
                    <StyledTableCell sx={{ fontSize: "11px" }}>
                      {new Date(row.created).toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontSize: "11px", wordBreak: "break-all", maxWidth: "180px" }}>
                      {row.address}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontSize: "11px" }}>
                      {Math.round(row.amount * 10) / 10} SDR
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default PaymentsTable;
