import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from "@mui/material/styles";

// Styled Components
const StyledCard = styled(Card)({
  background: "linear-gradient(135deg, #0e0f13 20%, #1b1f23 100%)",
  color: "white",
  borderRadius: "4px",
  boxShadow: "0px 4px 20px rgba(50, 224, 196, 0.3)",
  overflow: "hidden",
});

const StyledTableContainer = styled(TableContainer)({
  background: "transparent",
  boxShadow: "none",
  overflowX: "auto",
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: "#1b1f23",
});

const StyledTableCell = styled(TableCell)({
  padding: "4px",
  fontSize: "11px",
  fontWeight: "bold",
  textTransform: "uppercase",
  color: "rgba(50, 224, 196, 1)",
  textAlign: "left",
});

const StyledTableRow = styled(TableRow)(({ bgColor }) => ({
  background: bgColor,
  transition: "background 0.3s ease-in-out",
  "&:hover": { background: "rgba(50, 224, 196, 0.1)" },
}));

const StyledTableData = styled(TableCell)({
  padding: "3px",
  fontSize: "10px",
  color: "rgba(255, 255, 255, 0.8)",
  borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  textAlign: "center",
  verticalAlign: "middle",
  whiteSpace: "nowrap",
});

// Copy function
const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

// ✅ Server Lists for PPLNS & SOLO
const serverLists = {
  pplns: [
    { region: "EUROPE", type: "KS0/PRO", address: "stratum+tcp://sedrapool.com:3092" },
    { region: "EUROPE", type: "KS0 Ultra", address: "stratum+tcp://sedrapool.com:3093" },
    { region: "EUROPE", type: "KS1/KS2", address: "stratum+tcp://sedrapool.com:3094" },
    { region: "EUROPE", type: "KS3/L/M/KS5/L/M/K9", address: "stratum+tcp://sedrapool.com:3095" },
    { region: "USA", type: "KS0/PRO", address: "stratum+tcp://us.sedrapool.com:3092" },
    { region: "USA", type: "KS0 Ultra", address: "stratum+tcp://us.sedrapool.com:3093" },
    { region: "USA", type: "KS1/KS2", address: "stratum+tcp://us.sedrapool.com:3094" },
    { region: "USA", type: "KS3/L/M/KS5/L/M/K9", address: "stratum+tcp://us.sedrapool.com:3095" },
    { region: "ASIA", type: "KS0/PRO", address: "coming soon..." },
    { region: "ASIA", type: "KS0 Ultra", address: "coming soon..." },
    { region: "ASIA", type: "KS1/KS2", address: "coming soon..." },
    { region: "ASIA", type: "KS3/L/M/KS5/L/M/K9", address: "coming soon..." },
  ],
  solo: [
    { region: "EUROPE", type: "KS0/PRO", address: "stratum+tcp://sedrapool.com:3096" },
    { region: "EUROPE", type: "KS0 Ultra", address: "stratum+tcp://sedrapool.com:3097" },
    { region: "EUROPE", type: "KS1/KS2", address: "stratum+tcp://sedrapool.com:3098" },
    { region: "EUROPE", type: "KS3/L/M/KS5/L/M/K9", address: "stratum+tcp://sedrapool.com:3099" },
    { region: "USA", type: "KS0/PRO", address: "stratum+tcp://us.sedrapool.com:3096" },
    { region: "USA", type: "KS0 Ultra", address: "stratum+tcp://us.sedrapool.com:3097" },
    { region: "USA", type: "KS1/KS2", address: "stratum+tcp://us.sedrapool.com:3098" },
    { region: "USA", type: "KS3/L/M/KS5/L/M/K9", address: "stratum+tcp://us.sedrapool.com:3099" },
    { region: "ASIA", type: "KS0/PRO", address: "coming soon..." },
    { region: "ASIA", type: "KS0 Ultra", address: "coming soon..." },
    { region: "ASIA", type: "KS1/KS2", address: "coming soon..." },
    { region: "ASIA", type: "KS3/L/M/KS5/L/M/K9", address: "coming soon..." },
  ],
};

// Alternating row colors
const regionAlternatingColors = {
  EUROPE: ["transparent", "#1b1f23"],
  USA: ["transparent", "#1b1f23"],
  ASIA: ["transparent", "#1b1f23"],
};

// ✅ Mining Server List Component
const MiningServerList = ({ poolType = "pplns" }) => {
  const servers = serverLists[poolType] || serverLists["pplns"];

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h6" className="your-title" gutterBottom>
          CONNECT TO SEDRAPOOL ({poolType.toUpperCase()})
        </Typography>

        <StyledTableContainer component={Paper}>
          <Table>
            <StyledTableHead>
              <TableRow>
                <StyledTableCell>Region</StyledTableCell>
                <StyledTableCell>ASIC</StyledTableCell>
                <StyledTableCell>Connection</StyledTableCell>
              </TableRow>
            </StyledTableHead>

            <TableBody>
              {servers.map((server, index) => {
                const isFirstOfRegion = index === 0 || server.region !== servers[index - 1].region;
                const rowSpanCount = servers.filter((s) => s.region === server.region).length;
                const bgColor = regionAlternatingColors[server.region][index % 2];

                return (
                  <StyledTableRow key={index} bgColor={bgColor}>
                    {isFirstOfRegion && (
                      <StyledTableData rowSpan={rowSpanCount} sx={{ fontWeight: "bold", color: "#32e0c4", textAlign: "center" }}>
                        {server.region}
                      </StyledTableData>
                    )}
                    <StyledTableData>{server.type}</StyledTableData>
                    <StyledTableData sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      {server.address}
                      <Tooltip title="Copy Address">
                        <IconButton onClick={() => copyToClipboard(server.address)} sx={{ color: "#32e0c4", padding: "2px" }}>
                          <ContentCopyIcon sx={{ fontSize: "14px" }} />
                        </IconButton>
                      </Tooltip>
                    </StyledTableData>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </CardContent>
    </StyledCard>
  );
};

export default MiningServerList;
