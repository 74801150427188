import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';  // Global styles (this will include the root container)
import App from './App';  // Main App component
import * as serviceWorker from './serviceWorker';

// Use createRoot instead of ReactDOM.render
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Service worker setup
serviceWorker.unregister();
