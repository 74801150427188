import { useState, useEffect } from "react";

export default function RotationHint({ hideHint }) {
  const [hidden, setHidden] = useState(false);
  const [fading, setFading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    if (isMobile || hideHint) return; // Hide if mobile or dashboard is open

    const handleUserInteraction = () => {
      setFading(true); // Start fade effect
      setTimeout(() => setHidden(true), 700); // Hide after animation
    };

    document.addEventListener("pointerdown", handleUserInteraction, { once: true });

    return () => document.removeEventListener("pointerdown", handleUserInteraction);
  }, [isMobile, hideHint]);

  if (hidden || isMobile || hideHint) return null; // Ensure complete removal when needed

  return (
    <div
      style={{
        position: 'absolute',
        top: '65%',
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
        zIndex: 9999,
        transition: 'opacity 700ms ease-in-out',
        opacity: fading ? 0 : 1,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#ffffff', userSelect: 'none', animation: 'sway 4.0s infinite ease-in-out' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <svg
            width="24" height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#9ca3af"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 12H5"></path>
            <path d="M12 19l-7-7 7-7"></path>
          </svg>

          <img
            src="/icons/pointing_finger.svg"
            alt="Pointing Finger"
            style={{ width: '30px', height: '30px', objectFit: 'contain' }}
          />

          <svg
            width="24" height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#9ca3af"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M5 12h14"></path>
            <path d="M12 5l7 7-7 7"></path>
          </svg>
        </div>

        <p style={{
          marginTop: '8px',
          fontSize: '14px',
          fontWeight: '500',
          color: '#d1d5db',
          lineHeight: '1.25',
          textAlign: 'center',
        }}>
          Click & hold<br />to explore
        </p>
      </div>

      <style>
        {`
          @keyframes sway {
            0%, 100% { transform: translateX(0); }
            50% { transform: translateX(-40px); }
          }

          .animate-sway {
            animation: sway 4.0s infinite ease-in-out;
          }
        `}
      </style>
    </div>
  );
}
