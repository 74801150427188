import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";

const SetThreshold = ({ walletAddress, resetTrigger }) => { // ✅ Added resetTrigger
  const [threshold, setThreshold] = useState("");
  const [currentThreshold, setCurrentThreshold] = useState(null);
  const [ipAddress, setIpAddress] = useState("");
  const [manualIp, setManualIp] = useState(""); // ✅ User manually enters their IP
  const [isIpVerified, setIsIpVerified] = useState(false); // ✅ Track IP verification status
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [workerCheckFailed, setWorkerCheckFailed] = useState(false);

  // ✅ Reset State When `resetTrigger` Changes
  useEffect(() => {
    setThreshold("");        // ✅ Clear input field
    setCurrentThreshold(null); // ✅ Reset current threshold
    setManualIp("");        // ✅ Clear manually entered IP
    setIsIpVerified(false);  // ✅ Reset IP verification
    setSuccessMessage(null);
    setError(null);
    setWorkerCheckFailed(false);
  }, [resetTrigger]); // ✅ Always reset when resetTrigger changes  
  
  // ✅ Fetch current threshold
  useEffect(() => {
    if (!walletAddress) return;

    const fetchThreshold = async () => {
      try {
        const response = await fetch(
          `https://sedrapool.com/api/pools/sdr/miners/${walletAddress}/settings`
        );
        const data = await response.json();
        console.log("API Response:", data);  // ✅ Debugging: Check what the API returns
    
        if (data && Object.keys(data).length > 0 && typeof data.paymentThreshold !== "undefined") {
          setCurrentThreshold(data.paymentThreshold);
        } else {
          setCurrentThreshold("Not set");
        }
      } catch (error) {
        console.error("Error fetching threshold:", error);
        setCurrentThreshold("Not set"); // Handle failure gracefully
      }
    };    

    fetchThreshold();
  }, [walletAddress]);

  // ✅ Fetch user's IP address
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await fetch("https://api64.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIp();
  }, []);

  // ✅ Step 1: Verify IP
  const handleVerifyIp = () => {
    if (manualIp === ipAddress) {
      setIsIpVerified(true);
      setError(null);
      setSuccessMessage("✅ IP Verified Successfully!");
    } else {
      setIsIpVerified(false);
      setSuccessMessage(null);
      setError("⚠ Entered IP does not match detected IP.");
    }
  };

  // ✅ Step 2: Check active workers before submission
  const checkWorkers = async () => {
    try {
      const response = await fetch(
        `https://sedrapool.com/api/pools/sdr/miners/${walletAddress}`
      );
      const data = await response.json();
      const hasWorkers =
        data.performance?.workers && Object.keys(data.performance.workers).length > 0;

      if (!hasWorkers) {
        setWorkerCheckFailed(true);
        return false;
      }

      return true;
    } catch (error) {
      console.error("Error checking workers:", error);
      setWorkerCheckFailed(true);
      return false;
    }
  };

  // ✅ Step 3: Set Threshold
  const handleSubmit = async () => {
    if (!walletAddress || !threshold || isNaN(threshold) || threshold <= 0) {
      setError("⚠ Please enter a valid threshold amount.");
      return;
    }

    if (!isIpVerified) {
      setError("⚠ You must verify your IP before setting the threshold.");
      return;
    }

    setError(null);
    setSuccessMessage(null);
    setLoading(true);

    const workersValid = await checkWorkers();
    if (!workersValid) {
      setLoading(false);
      return;
    }

    const requestBody = {
      settings: { PaymentThreshold: threshold },
      ipAddress: manualIp,
    };

    try {
      const response = await fetch(
        `https://sedrapool.com/api/pools/sdr/miners/${walletAddress}/settings`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setSuccessMessage("✅ Threshold updated successfully!");
        setCurrentThreshold(threshold);
        setThreshold(""); // ✅ Clear input field
      } else {
        setError(result.message || "❌ Error updating threshold.");
      }
    } catch (error) {
      console.error("Error setting threshold:", error);
      setError("❌ Failed to update threshold.");
    }

    setLoading(false);
    setTimeout(() => {
      setOpen(false);
      setWorkerCheckFailed(false);
      setIsIpVerified(false); // ✅ Reset verification
    }, 2000);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      {walletAddress && currentThreshold ? (
        // ✅ Show only when a wallet is loaded
        <>
          <Typography sx={{ fontSize: "14px" }}>
            Current: {currentThreshold}
          </Typography>
  
          <Button
            variant="contained"
            sx={{
              minWidth: "auto",
              padding: "4px 8px",
              fontSize: "10px",
              fontWeight: "bold",
              lineHeight: "1",
            }}
            onClick={() => setOpen(true)}
          >
            Set
          </Button>
        </>
      ) : (
        // ✅ Show when wallet is not loaded (or after reset)
        <Typography sx={{ fontSize: "14px", textAlign: "center" }}>
          Load wallet to see info
        </Typography>
      )}
  
      {/* ✅ Modal for Setting Threshold */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            textAlign: "center",
            borderRadius: 4,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, color: "white" }}>
            Set Payment Threshold
          </Typography>
  
          {/* ✅ Display masked detected IP */}
          <Typography variant="body2" sx={{ mb: 1, color: "gray" }}>
            Detected IP:{" "}
            {ipAddress
              ? `${ipAddress.split(".")[0]}.${ipAddress.split(".")[1]}.*.*.${ipAddress.split(".").slice(-1)}`
              : "Loading..."}
          </Typography>
  
          {/* ✅ Step 1: Enter & Verify IP */}
          <TextField
            fullWidth
            label="Confirm Your IP Address"
            variant="outlined"
            value={manualIp}
            onChange={(e) => setManualIp(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            fullWidth
            onClick={handleVerifyIp}
            disabled={!manualIp}
            sx={{ mb: 2 }}
          >
            Verify IP
          </Button>
  
          <TextField
            fullWidth
            type="number"
            label="Threshold Amount"
            variant="outlined"
            value={threshold}
            onChange={(e) => setThreshold(e.target.value)}
            disabled={!isIpVerified} // ✅ Disabled until IP is verified
            sx={{ mb: 2 }}
          />
  
          {error && <Alert severity="error">{error}</Alert>}
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          {workerCheckFailed && (
            <Alert severity="error">
              ❌ You need at least one active worker to change the threshold.
            </Alert>
          )}
  
          {/* ✅ Step 3: Submit Threshold */}
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            disabled={loading || !isIpVerified}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={20} sx={{ color: "white" }} /> : "Set Threshold"}
          </Button>
        </Box>
      </Modal>
    </Box>
  );  
};

export default SetThreshold;
