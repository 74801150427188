import React, { useState, useEffect } from "react";
import MinersCount from "./MinersCount";
import PoolHashrate from "./PoolHashrate";
import AvgPoolEffort from "./AvgPoolEffort";
import HashrateChart from "./HashrateChart";
import MinerList from "./MinerList";
import BlockReward from "./BlockReward";
import NetworkHashrate from "./NetworkHashrate";
import RecentEffortsTable from "./RecentEffortsTable";
import TopMinersTable from "./TopMinersTable";
import MiningServerList from "./MiningServerList";
import SetThreshold from "./SetThreshold";
import PaymentsTable from "./PaymentsTable";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
  Stack,
  Tooltip,
} from "@mui/material";
import "../App.css";

// ✅ Compact Card Styling for Stats Boxes
const CompactCard = styled(Card)(({ theme }) => ({
  color: "white",
  borderRadius: "12px", // Rounded for a softer, modern feel
  textAlign: "center",
  background: "linear-gradient(135deg, #10131A 30%, #1E2A38 100%)", // Dark futuristic gradient
  boxShadow: "0px 0px 12px rgba(0, 255, 255, 0.15)", // Subtle glowing effect
  transition: "transform 0.25s ease-in-out, box-shadow 0.4s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)", // Slightly more noticeable hover scaling
    boxShadow: "0px 0px 25px rgba(0, 255, 255, 0.5)", // Stronger neon glow
  },
  border: "1px solid rgba(0, 255, 255, 0.2)", // Subtle neon border for a sci-fi look
}));

const GlowingIndicator = styled("span")(({ theme }) => ({
  display: "inline-block",
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  backgroundColor: "#00FF00", // ✅ Green color for "Online"
  boxShadow: "0px 0px 6px 2px rgba(0, 255, 0, 0.6)", // ✅ Soft glow
  animation: "pulse 1.5s infinite alternate",
  marginRight: "8px", // ✅ Space before text
  "@keyframes pulse": {
    "0%": { transform: "scale(1)", opacity: 1 },
    "100%": { transform: "scale(1.2)", opacity: 0.8 },
  },
}));

const DashboardPPLNS = () => {
  const [inputWallet, setInputWallet] = useState("");
  const [walletData, setWalletData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resetTrigger, setResetTrigger] = useState(0);

  useEffect(() => {
    const savedWallet = localStorage.getItem("wallet_pplns");
    if (savedWallet && savedWallet.trim()) {
      setInputWallet(savedWallet);
      fetchWalletStats(savedWallet);
    }
  }, []);

  const isValidWallet = (wallet) => wallet.startsWith("sedra:") && wallet.length > 40;

  const fetchWalletStats = async (walletAddress) => {
    if (!walletAddress.trim()) return;
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://sedrapool.com/api/pools/sdr/miners/${walletAddress}`);
      if (!response.ok) throw new Error(`API Error: ${response.status} ${response.statusText}`);

      const data = await response.json();
      setWalletData(data);
    } catch (error) {
      setError(error.message || "Failed to fetch wallet data.");
      setWalletData(null);
    } finally {
      setLoading(false);
    }
  };

  const handleWalletSubmit = () => {
    const trimmedWallet = inputWallet.trim();

    if (!trimmedWallet || !isValidWallet(trimmedWallet)) {
      setWalletData(null);
      setError("Invalid wallet address.");
      return;
    }

    setError(null);
    localStorage.setItem("wallet_pplns", trimmedWallet);
    fetchWalletStats(trimmedWallet);
  };

  const handleReset = () => {
    setInputWallet("");
    setWalletData(null);
    setError(null);
    setResetTrigger((prev) => prev + 1); // ✅ Forces MinerList to reset
    localStorage.removeItem("wallet_pplns");
  };

  return (
    <div className="dashboard-container">
      <Card className="pool-stats-card">
        <CardContent>
          <Typography 
            variant="subtitle1" 
            gutterBottom 
            sx={{ 
              fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
              fontFamily: "'Russo One', sans-serif",
              textTransform: "uppercase",
              textAlign: "left" // ✅ Ensures text is left-aligned
            }}
          >
            Pool PPLNS Stats
          </Typography>

          {/* ✅ Compact Mining Pool Stats */}
          <Stack 
            direction="row" 
            flexWrap="wrap" 
            justifyContent="center" 
            sx={{ gap: "6px" }} // ✅ Less gap to fit more boxes
          >
            {[
              { title: "Miners Online", value: <MinersCount type="pplns" />, showIndicator: true },
              { title: "Pool Hashrate", value: <PoolHashrate type="pplns" average />, showIndicator: true },
              { title: "Pool Effort", value: <AvgPoolEffort type="pplns" blocksToAverage={10} /> },
              { title: "Min. Payout", value: "500 SDR" },
              { title: "Block Reward", value: <BlockReward /> },
              { title: "Network Hashrate", value: <NetworkHashrate /> },
              { title: "Pool Fee", value: "0.5%" },
            ].map((stat, index) => (
              <Box key={index} sx={{ flex: "1 1 130px", textAlign: "center", padding: "5px" }}>
                <CompactCard>
                <CardContent
                  sx={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    alignItems: "center", 
                    justifyContent: "center", 
                    flexGrow: 1,  
                    height: "100%", 
                    padding: "12px 8px", // ✅ Balanced spacing
                    "&:last-child": { paddingBottom: "12px" }, // ✅ Overrides MUI's 24px bottom padding
                  }}
                >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "6px" }}>
                      {stat.showIndicator && <GlowingIndicator />} {/* ✅ Add green glowing dot */}
                      <Typography sx={{ fontSize: "11px", fontWeight: "bold" }}>{stat.title}</Typography>
                    </div>
                    <Typography component="div" sx={{ fontSize: "14px" }}>{stat.value}</Typography>
                  </CardContent>
                </CompactCard>
              </Box>
            ))}
          </Stack>
        </CardContent>
      </Card>

      {/* Wallet & Miner List Section */}
      <Card sx={{ padding: "10px", marginTop: "15px" }}>
        <CardContent>
          <Typography 
            variant="subtitle1" 
            gutterBottom 
            sx={{ 
              fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
              fontFamily: "'Russo One', sans-serif",
              textTransform: "uppercase",
              textAlign: "left" // ✅ Ensures text is left-aligned
            }}
          >
            Your PPLNS Stats
          </Typography>
          <Stack 
            direction={{ xs: "column", sm: "row" }}  // ✅ Column on mobile, row on larger screens
            spacing={2} 
            alignItems="center" 
            justifyContent="center"  // ✅ Centering elements
            sx={{ width: "100%" }}  // ✅ Full width container
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Your Wallet Address"
              value={inputWallet}
              onChange={(e) => setInputWallet(e.target.value)}
              className="wallet-input"
            />
            <Stack direction="row" spacing={1} sx={{ width: { xs: "100%", sm: "auto" }, justifyContent: "center" }}>
              <Button variant="contained" color="primary" sx={{ fontWeight: "bold", flex: 1 }} onClick={handleWalletSubmit}>
                Lookup
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ fontWeight: "bold", flex: 1 }} // ✅ Same width on mobile
                onClick={handleReset}
              >
                Reset
              </Button>
            </Stack>
          </Stack>
          {loading && <CircularProgress sx={{ marginTop: "10px" }} />}
          {error && <Typography color="error">{error}</Typography>}
        </CardContent>

        {/* ✅ Miner List now resets properly */}
        <CardContent>
          <MinerList walletAddress={inputWallet} resetTrigger={resetTrigger} type="pplns" />
        </CardContent>

{/* Wallet Data Display (Clears when clicking Reset) */}
<Stack direction="row" flexWrap="wrap" justifyContent="center" sx={{ gap: "6px" }}>
  {[
    { 
      title: "Pending Balance", 
      value: walletData?.pendingBalance !== undefined 
        ? (walletData.pendingBalance > 0 
            ? `${(Math.round(walletData.pendingBalance * 10) / 10)} SDR` 
            : "0 SDR") 
        : "Load wallet to see info"
    },
    { 
      title: "Paid Today", 
      value: walletData?.todayPaid !== undefined 
        ? (walletData.todayPaid > 0 
            ? `${(Math.round(walletData.todayPaid * 10) / 10)} SDR` 
            : "0 SDR") 
        : "Load wallet to see info"
    },
    { 
      title: "Green Rewards", 
      value: walletData ? (
        <>
          <Typography variant="caption" sx={{ fontSize: "10px", color: "rgba(255,255,255,0.7)" }}>
            🌳 Trees planted by your hashrate: <strong>1</strong>
          </Typography>
          <Typography variant="caption" sx={{ fontSize: "10px", color: "rgba(255,255,255,0.7)", display: "flex", alignItems: "center", gap: "4px" }}>
            Rewards for your planted tree: <strong>50K SDR</strong>
            <Tooltip title="Rewards may take up to 3 days to process before it appears in your wallet" arrow>
              <HelpOutlineIcon sx={{ fontSize: "12px", color: "rgba(255,255,255,0.5)", cursor: "pointer" }} />
            </Tooltip>
          </Typography>
        </>
      ) : "Load wallet to see info"
    },
    { 
      title: "Total Paid", 
      value: walletData?.totalPaid !== undefined 
        ? (walletData.totalPaid 
            ? `${(Math.round(walletData.totalPaid * 10) / 10)} SDR` 
            : "-") 
        : "Load wallet to see info"
    },    
  ].map((stat, index) => (
    <Box key={index} sx={{ flex: "1 1 140px", textAlign: "center", padding: "5px" }}>
      <CompactCard>
        <CardContent>
          <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>{stat.title}</Typography>
          <Typography sx={{ fontSize: "14px" }}>{stat.value}</Typography>
        </CardContent>
      </CompactCard>
    </Box>
  ))}

  {/* ✅ NEW: Payment Threshold Box */}
  <Box sx={{ flex: "1 1 140px", textAlign: "center", padding: "5px" }}>
    <CompactCard>
      <CardContent>
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>Payment Threshold</Typography>
        <SetThreshold walletAddress={inputWallet} resetTrigger={resetTrigger} />
      </CardContent>
    </CompactCard>
  </Box>
</Stack>
{/* ✅ Add RecentEffortsTable INSIDE the same "Your PPLNS Stats" Card */}
<CardContent sx={{ marginTop: "15px" }}>
  <Typography 
    variant="subtitle1" 
    gutterBottom 
    sx={{ 
      fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
      fontFamily: "'Russo One', sans-serif",
      textTransform: "uppercase",
      textAlign: "left"
    }}
  >
  </Typography>
  <RecentEffortsTable walletAddress={inputWallet} type="pplns" />
</CardContent>

    </Card>

      <Box sx={{ marginTop: "15px", textAlign: "center" }}>
        <Stack 
          direction={{ xs: "column", md: "row" }} 
          spacing={2} 
          sx={{ width: "100%", justifyContent: "center", alignItems: "stretch" }}
          >
          {/* 📌 LEFT COLUMN - 60% WIDTH */}
          <Box sx={{ flex: { xs: "1", md: "0 0 57%" }, minWidth: 300, display: "flex", flexDirection: "column", gap: 2 }}>

            {/* 📌 Top Miners Table (Directly Below Recent Efforts Table with 10px margin) */}
            <Card>
              <CardContent>
                <TopMinersTable poolType="pplns" />
              </CardContent>
            </Card>
          </Box>

          {/* 📌 RIGHT COLUMN - 40% WIDTH */}
          <Box sx={{ flex: { xs: "1", md: "0 0 38%" }, minWidth: 300, display: "flex", flexDirection: "column", gap: 2 }}>
            <Card>
              <CardContent>
                <HashrateChart poolType="sdr" />
              </CardContent>
            </Card>
          </Box>
        </Stack>
        {/* 📌 PAYMENTS + MINING SERVERS SECTION (Same layout as above) */}
<Stack
  direction={{ xs: "column", md: "row" }}
  spacing={2}
  sx={{ width: "100%", justifyContent: "center", alignItems: "stretch", marginTop: "15px" }}
>
  {/* 📌 LEFT COLUMN - 60% WIDTH */}
  <Box
    sx={{
      flex: { xs: "1", md: "0 0 57%" },
      minWidth: 300,
      display: "flex",
      flexDirection: "column",
      gap: 2,
    }}
  >
    <Card>
      <CardContent>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
            fontFamily: "'Russo One', sans-serif",
            textTransform: "uppercase",
            textAlign: "left",
          }}
        >
        </Typography>
        <PaymentsTable />
      </CardContent>
    </Card>
  </Box>

  {/* 📌 RIGHT COLUMN - 40% WIDTH */}
  <Box
    sx={{
      flex: { xs: "1", md: "0 0 38%" },
      minWidth: 300,
      display: "flex",
      flexDirection: "column",
      gap: 2,
    }}
  >
    <Card>
      <CardContent>
        <MiningServerList poolType="pplns" />
      </CardContent>
    </Card>
  </Box>
</Stack>

      </Box>
    </div>
  );
};

export default DashboardPPLNS;
