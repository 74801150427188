import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// ✅ Dynamically determine API URL based on `type` (PPLNS or SOLO)
const getBlocksApiUrl = (type) => {
  return type === "solo"
    ? "https://sedrapool.com/api/pools/sdr-solo/miners/"
    : "https://sedrapool.com/api/pools/sdr/miners/";
};

// ✅ Esports-Inspired Card Container
const StyledCard = styled(Card)({
  background: "linear-gradient(135deg, #10131A 30%, #1E2A38 100%)",
  color: "white",
  borderRadius: "12px",
  boxShadow: "0px 0px 12px rgba(0, 255, 255, 0.15)", // Subtle glowing effect
  padding: "10px",
  textAlign: "center",
  minWidth: "280px",
  border: "1px solid rgba(0, 255, 255, 0.2)", // Neon border
  "&:hover": {
    boxShadow: "0px 0px 20px rgba(0, 255, 255, 0.5)", // Stronger neon glow on hover
  },
});

// ✅ Sci-Fi Table Container
const StyledTableContainer = styled(TableContainer)({
  backgroundColor: "#181b23", // Dark futuristic background
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 255, 255, 0.15)", // Subtle outer glow
  maxHeight: "250px", // ✅ Prevents unnecessary growth
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 255, 255, 0.5)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#1b1f23",
  },
});

// ✅ Neon-Glowing Table Header
const StyledTableHead = styled(TableHead)(({ theme }) => ({
  boxShadow: "0px 0px 10px rgba(255, 255, 255, 0.1)", 
  [theme.breakpoints.up("sm")]: {
    background: "linear-gradient(90deg, #00E5FF 0%, #FFCC00 100%)", // ✅ Gradient for desktop
  },
  [theme.breakpoints.down("sm")]: {
    backgroundColor: "#00E5FF", // ✅ Solid color for mobile
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#FFFFFF",
  borderBottom: "1px solid rgba(255, 255, 255, 0.15)",
  textAlign: "center",
  fontSize: "12px", // Default small size for mobile
  padding: "6px 8px",
  transition: "background 0.3s ease-in-out",
  [theme.breakpoints.up("sm")]: {
    fontSize: "9px", // Increase size on tablets
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "10px", // Larger size on laptops
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "13px", // Bigger for ultra-wide screens
  },
  [theme.breakpoints.up("xxl")]: {
    fontSize: "18px", // Bigger for ultra-wide screens
  },
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
}));

// ✅ Table Header Cells (Bold & Neon)
const StyledTableHeaderCell = styled(StyledTableCell)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "11px", // Default font size
  textTransform: "uppercase",
  color: "#10131A", // Dark text for contrast on neon background
  borderBottom: "2px solid rgba(0, 255, 255, 0.4)", // Stronger neon border
  [theme.breakpoints.up("md")]: {
    fontSize: "13px", // Larger font size for screens larger than "md"
  },
}));


// ✅ Sci-Fi Row Striping
const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": { backgroundColor: "#22262e" }, // Dark gray
  "&:nth-of-type(even)": { backgroundColor: "#2b303a" }, // Slightly lighter gray
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.07)", // Neon hover effect
  },
});

// ✅ Glowing Links (Styled for esports UI)
const StyledLink = styled("a")({
  color: "#00E5FF", // Futuristic neon cyan
  textDecoration: "none",
  fontWeight: "bold",
  fontSize: "11px",
  transition: "color 0.3s ease-in-out",
  "&:hover": { color: "#FFCC00" }, // Overwatch gold on hover
  "&:active": { color: "#c0c0c0" },
  "&:visited": { color: "#ADD8E6" },
});

// ✅ Compact Table Component
const RecentEffortsTable = ({ walletAddress, type = "pplns" }) => {
  const BLOCKS_API_URL = getBlocksApiUrl(type);
  const [effortHistory, setEffortHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!walletAddress) return;

    fetchEffortData(walletAddress);
    const interval = setInterval(() => {
      fetchEffortData(walletAddress);
    }, 300000); // Fetch every 5 minutes

    return () => clearInterval(interval);
  }, [walletAddress, type]);

  const fetchEffortData = async (address) => {
    setLoading(true);
    setError("");

    try {
      const response = await fetch(`${BLOCKS_API_URL}${address}/blocks?page=0&pageSize=10`);
      if (!response.ok) throw new Error(`API Error: ${response.status}`);

      const data = await response.json();
      if (!data || !Array.isArray(data)) {
        throw new Error("Invalid API response");
      }

      if (data.length > 0) {
        const updatedHistory = data.map((block) => {
          const timeAgo = getTimeAgo(new Date(block.created));

          let rawEffort = block.minerEffort !== undefined ? block.minerEffort : block.effort;
          let blockEffort = rawEffort !== undefined && rawEffort !== null
            ? (rawEffort * 1e12).toFixed(2)
            : "N/A";

          let networkDiffP = block.networkDifficulty
            ? (block.networkDifficulty / 1e15).toFixed(2) + " P"
            : "N/A";

          return {
            time: timeAgo,
            effort: blockEffort !== "N/A" ? `${blockEffort}%` : "N/A",
            blockHeight: block.blockHeight,
            networkDifficulty: networkDiffP,
            reward: block.reward ? block.reward.toFixed(2) : "N/A",
            status: block.status || "Unknown",
            infoLink: block.infoLink || "#",
          };
        });

        setEffortHistory(updatedHistory);
      } else {
        setEffortHistory([]);
      }
    } catch (err) {
      setError("Error fetching miner effort history.");
      setEffortHistory([]);
    } finally {
      setLoading(false);
    }
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const diffMs = now - timestamp;
    const diffMinutes = Math.floor(diffMs / 1000 / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffMinutes < 1) return "Just now";
    if (diffMinutes < 60) return `${diffMinutes}m ago`;
    if (diffHours < 24) return `${diffHours}h ago`;
    return `${diffDays}d ago`;
  };

  return (
    <StyledCard>
      <CardContent sx={{ padding: "10px" }}>
      <Typography 
        variant="subtitle1" 
        gutterBottom 
        sx={{ 
          fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
          fontFamily: "'Russo One', sans-serif",
          textTransform: "uppercase",
          textAlign: "left" // ✅ Ensures text is left-aligned
        }}
      >
        YOUR {type.toUpperCase()} Blocks
      </Typography>

        {loading ? (
          <CircularProgress sx={{ color: "white", marginTop: "10px" }} size={18} />
        ) : error ? (
          <Typography color="error" sx={{ fontSize: "12px" }}>{error}</Typography>
        ) : (
          <StyledTableContainer component={Paper}>
            <Table size="small"> {/* ✅ Makes table smaller */}
              <StyledTableHead>
                <TableRow>
                  <StyledTableHeaderCell>Time</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Effort</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Block</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Diff</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Reward</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Status</StyledTableHeaderCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {effortHistory.length > 0 ? (
                  effortHistory.map((entry, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell sx={{ fontSize: "11px" }}>{entry.time}</StyledTableCell>
                      <StyledTableCell sx={{ fontSize: "11px" }}>{entry.effort}</StyledTableCell>
                      <StyledTableCell sx={{ fontSize: "11px" }}>
                        <StyledLink href={entry.infoLink} target="_blank" rel="noopener noreferrer">
                          {entry.blockHeight}
                        </StyledLink>
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontSize: "11px" }}>{entry.networkDifficulty}</StyledTableCell>
                      <StyledTableCell sx={{ fontSize: "11px" }}>{entry.reward}</StyledTableCell>
                      <StyledTableCell sx={{ fontSize: "11px" }}>{entry.status}</StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={6} sx={{ textAlign: "center", fontSize: "11px" }}>
                      No recent efforts found
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default RecentEffortsTable;
