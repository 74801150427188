import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { styled } from "@mui/material/styles";
import { Card, CardContent, Typography } from "@mui/material";

const API_URL = "https://sedrapool.com/api/pools/sdr/performance";
const API_URL_SOLO = "https://sedrapool.com/api/pools/sdr-solo/performance";

// Styled Card with gradient and glowing effect
const OverwatchCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(135deg, #1b1f23,rgb(19, 19, 22))", // Gradient for card background
  color: "white",
  borderRadius: "15px",
  boxShadow: "0px 4px 15px rgba(255, 255, 255, 0.2)",
  padding: "20px",
  backdropFilter: "blur(5px)", // Adds a blur effect to the background for more depth
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease", // Smooth transition for hover effect
  "&:hover": {
    boxShadow: "0px 10px 30px rgba(255, 255, 255, 0.3)", // Glow effect on hover
  },
}));

const HashrateChart = ({ poolType }) => {
  const [data, setData] = useState([]);
  const [dataSolo, setDataSolo] = useState([]);

  useEffect(() => {
    const fetchHashrateData = async () => {
      try {
        const response = await fetch(API_URL);
        const responseSolo = await fetch(API_URL_SOLO);
        if (!response.ok || !responseSolo.ok) throw new Error("API fetch error");

        const result = await response.json();
        const resultSolo = await responseSolo.json();

        // Process Data (Convert H/s -> TH/s)
        const formattedData = result.stats.map((entry) => ({
            time: new Date(entry.created).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
            SDR: Math.round((entry.poolHashrate / 1e12) * 10) / 10, // Unique key "SDR"
        }));
        
        const formattedDataSolo = resultSolo.stats.map((entry) => ({
            time: new Date(entry.created).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
            SOLO: Math.round((entry.poolHashrate / 1e12) * 10) / 10, // Unique key "SOLO"
        }));

        setData(formattedData);
        setDataSolo(formattedDataSolo);
      } catch (error) {
        console.error("Error fetching hashrate data:", error);
      }
    };

    fetchHashrateData();
  }, []);

  // Decide which data to render based on the poolType prop
  const chartData = poolType === "solo" ? dataSolo : data;

  return (
    <OverwatchCard>
      <CardContent>
        <Typography 
          variant="subtitle1" 
          gutterBottom 
          sx={{ 
            fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
            fontFamily: "'Russo One', sans-serif",
            textTransform: "uppercase",
            textAlign: "left",
            letterSpacing: "1.5px", // Adds more spacing for a clean, stylish look
            color: "#ff006e" // Neon pink color for title
          }}
        >
          {poolType === "solo" ? "Solo Pool Hashrate (Last 24h)" : "PPLNS Pool Hashrate (Last 24h)"}
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chartData} margin={{ top: 20, right: 20, left: 0, bottom: 0 }}>
            <CartesianGrid stroke="#444" strokeDasharray="3 3" />
            <XAxis
                dataKey="time"
                stroke="white"
                tickFormatter={(value, index) => (index % 4 === 0 ? value : "")}
                tick={{ fontSize: 12, fontWeight: "bold" }}
            />
            <YAxis
                stroke="white"
                domain={["auto", "auto"]}
                tickFormatter={(val) => `${val} TH`}
                axisLine={false} 
                tickLine={false}
                tick={{ fontSize: 12, fontWeight: "bold" }}
            />
            <Tooltip
              formatter={(value) => [`${value} TH`]}
              labelFormatter={() => ""}
              contentStyle={{ backgroundColor: "#222", borderRadius: "5px", color: "white" }}
            />
            {poolType === "solo" ? (
              <Line 
                type="monotone" 
                dataKey="SOLO" 
                stroke="#ff006e" 
                strokeWidth={3} 
                dot={true} 
                activeDot={{ r: 8, stroke: "#ff006e", strokeWidth: 2, fill: "#fff" }}
                animationDuration={1000} // Smooth animation for line drawing
              />
            ) : (
              <Line 
                type="monotone" 
                dataKey="SDR" 
                stroke="#00c8ff" 
                strokeWidth={3} 
                dot={true} 
                activeDot={{ r: 8, stroke: "#00c8ff", strokeWidth: 2, fill: "#fff" }}
                animationDuration={1000}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </OverwatchCard>
  );
};

export default HashrateChart;
