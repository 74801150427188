import React, { useState, useEffect, Suspense } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import theme from "./theme";
import { Canvas } from "@react-three/fiber";
import { Sky, Stars, OrbitControls, Text } from "@react-three/drei";
import Grass from "./Grass";
import SustainabilityPopup from "./components/SustainabilityPopup";
import Logo from "./components/Logo";
import DashboardPPLNS from "./components/DashboardPPLNS";
import DashboardSOLO from "./components/DashboardSOLO";
import Scoreboard from "./components/Scoreboard";
import HUDCards from "./components/HUDCards";
import BlockDiscovery from "./components/BlockDiscovery";
import TermsOfServiceModal from "./components/TermsOfServiceModal";
import CountdownTrees from "./components/CountdownTrees";
import RotationHint from "./components/RotationHint";

const headerHeight = {
  xs: "170px",
  sm: "110px",
  md: "160px",
  lg: "170px",
  xl: "170px",
  xxl: "220px",
};

export default function App() {
  const [co2Saved, setCo2Saved] = useState(1130);
  const [totalBlocks ] = useState(null);
  const [showPPLNS, setShowPPLNS] = useState(false);
  const [showSOLO, setShowSOLO] = useState(false);
  const [computedHeaderHeight, setComputedHeaderHeight] = useState(headerHeight.md);
  const [openTermsModal, setOpenTermsModal] = useState(false);

  const handleCardClick = (cardType) => {
    setShowPPLNS(cardType === "pplns" ? !showPPLNS : false);
    setShowSOLO(cardType === "solo" ? !showSOLO : false);
  };

  useEffect(() => {
    const updateHeaderHeight = () => {
      const width = window.innerWidth;
      if (width >= theme.breakpoints.values.xxl) setComputedHeaderHeight(headerHeight.xxl);
      else if (width >= theme.breakpoints.values.xl) setComputedHeaderHeight(headerHeight.xl);
      else if (width >= theme.breakpoints.values.lg) setComputedHeaderHeight(headerHeight.lg);
      else if (width >= theme.breakpoints.values.md) setComputedHeaderHeight(headerHeight.md);
      else if (width >= theme.breakpoints.values.sm) setComputedHeaderHeight(headerHeight.sm);
      else setComputedHeaderHeight(headerHeight.xs);
    };

    updateHeaderHeight();
    window.addEventListener("resize", updateHeaderHeight);
    return () => window.removeEventListener("resize", updateHeaderHeight);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        height="100dvh"
        width="100vw"
        position="relative"
        overflow="hidden"
        sx={{
          minHeight: "100dvh",
          overscrollBehavior: "none",
          touchAction: "manipulation",
          WebkitOverflowScrolling: "touch",
          "--computed-header-height": computedHeaderHeight,
        }}
      >
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          sx={{
            flexDirection: { xs: "column", md: "row" }, // ✅ Default layout remains same, column on mobile
            boxShadow: "0px 4px 12px rgba(0, 255, 255, 0.3)", 
            height: computedHeaderHeight,
            paddingX: 2,
            background: "linear-gradient(90deg, #10131A 0%, #10131A 30%, #1E2A38 70%, #10131A 100%)", 
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              boxShadow: "0px 6px 20px rgba(0, 255, 255, 0.6)",
            },
          }}
        >
          {/* LEFT: LOGO + SustainabilityPopup (ONLY on mobile) */}
          <Box
            sx={{ 
              flex: "0 0 auto", 
              display: "flex", 
              alignItems: "center",
              gap: 1, // ✅ Adds spacing between Logo & SustainabilityPopup
              mr: { md: 7, xl: 10 }, // ✅ Only add right margin on larger screens
            }}
          >
            <Logo />
            {/* ✅ Show SustainabilityPopup on the same row as Logo on mobile */}
            <Box
              sx={{
                display: { xs: "flex", sm: "none" }, // ✅ Only show on mobile
                maxWidth: { xs: "170px" }, // ✅ Set max width only for xs
                width: "100%", // ✅ Ensures it stays responsive within maxWidth
              }}
            >  
              <SustainabilityPopup />
            </Box>
          </Box>
          {/* CENTER: SUSTAINABILITY MESSAGE (Hidden on mobile) */}
          <Box 
            sx={{ 
              flex: "0 0 auto", // Prevents it from expanding too much
              display: { xs: "none", sm: "flex" }, 
              justifyContent: "center", 
              alignItems: "center",
              maxWidth: "25%", // 🔽 Reduce width (adjust as needed)
              width: "100%", 
            }}
          >
            <SustainabilityPopup />
          </Box>

          {/* RIGHT: BLOCK DISCOVERY (No Change) */}
          <Box
            sx={{
              flex: 1,
              display: { xs: "none", sm: "flex" }, // Hide on xs, show on sm and larger
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BlockDiscovery />
          </Box>

          {/* RIGHT: STATS */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "repeat(1, 1fr)", sm: "repeat(1, 1fr)" }, // ✅ 4 columns (1 row) on xs, 2 columns on sm+
              gridGap: { xs: 1, sm: 2, md: 3 },
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              maxWidth: { xs: 360, sm: 400 },
              margin: "auto",
              marginRight: { sm: 3, md: 4 }, // ✅ Ensures space on the right
            }}
          >
            <CountdownTrees />
          </Box>
        </Box>

      {/* 🌙 NIGHT SCENE */}
      <Box
        component="div"
        position="absolute"
        sx={{
          top: computedHeaderHeight,
          left: 0,
          width: "100%",
          height: `calc(100dvh - var(--computed-header-height))`,
          zIndex: 0,  // ✅ Ensure it's behind UI but still interactive
          pointerEvents: "all", // ✅ Ensure OrbitControls can detect scroll events
          overflow: "hidden", // ✅ Prevents unwanted scroll issues
        }}
      >
          <Canvas camera={{ position: [35, 20, 35] }} dpr={1} shadows fog={{ color: "#0b0c10", near: 20, far: 120 }}>
            <Sky distance={2550} sunPosition={[0, -10, 0]} inclination={0} azimuth={0.25} mieCoefficient={0.1} rayleigh={0.2} />
            <Stars radius={200} depth={50} count={5000} factor={6} saturation={0} fade />
            <ambientLight intensity={0.05} color="#444" />
            <directionalLight position={[50, 100, -50]} intensity={0.3} color="#aabbff" />
            <Suspense fallback={null}>
              <Grass position={[0, -14, 0]} frustumCulled />
            </Suspense>
            <Text
              position={[0, 7, 0]}
              fontSize={3}
              color="#ddddff"
              rotation={[0, Math.PI / 4, 0]} // Rotate 45 degrees around the Y-axis
              >Your hashrate is planting real-world trees,</Text>
            <Text
              position={[0, 3, 0]}
              fontSize={3}
              color="#bbbbff"
              rotation={[0, Math.PI / 4, 0]} // Rotate 45 degrees around the Y-axis
              >watch them grow here too</Text>
            <OrbitControls 
              enablePan={false}    
              enableZoom={false}   
              enableRotate={true} // ✅ ENABLE THIS!
              minPolarAngle={0}    
              maxPolarAngle={Math.PI / 2} // (optional) Limit vertical rotation to 90 degrees
              enableDamping={true}  // (optional) Smooth orbit experience
              dampingFactor={0.1}   // (optional) Adjust smoothness of orbit
              target={[0, 0, 0]}    
            />
         </Canvas>
         <RotationHint hideHint={showPPLNS || showSOLO} />
        </Box>

        {/* HUD & DASHBOARD */}
        <Box
          display="flex"
          width="100%"
          height={`calc(100dvh - var(--computed-header-height))`}
          zIndex={1}
          sx={{ pointerEvents: "none" }} // <-- ✅ Add this line
        >
          {/* LEFT HUD (HUD Cards) */}
          <Box
            width="20%"
            sx={{ padding: "15px", pointerEvents: "auto" }} // ✅ Enable interaction here
          >
            <HUDCards
              showPPLNS={showPPLNS}
              showSOLO={showSOLO}
              handleCardClick={handleCardClick}
              hudHeight="100%"
            />
          </Box>

          {/* RIGHT: DASHBOARD */}
          <Box
            width="80%"
            height="100%"
            padding="8px 18px 0 18px"
            overflow={showPPLNS || showSOLO ? "auto" : "hidden"}
            sx={{ pointerEvents: showPPLNS || showSOLO ? "auto" : "none" }}
          >
            {showPPLNS && <DashboardPPLNS />}
            {showSOLO && <DashboardSOLO />}
          </Box>

          {/* RIGHT-ALIGNED SCOREBOARD */}
          {!showPPLNS && !showSOLO && (
            <Box
              position="absolute"
              right="10px"
              top={computedHeaderHeight}
              width="280px"
              height={`calc(100dvh - var(--computed-header-height) - 20px)`}
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding="16px"
              sx={{ pointerEvents: "auto" }} // ✅ Enable interaction here too
            >
              <Scoreboard />
            </Box>
          )}
        </Box>
     {/* FOOTER */}
     {!showPPLNS && !showSOLO && (
          <Box
            component="footer"
            sx={{
              width: "100%",
              textAlign: { xs: "right", sm: "center" },
              color: "#c0c0c0",
              fontSize: { xs: "8px", sm: "10px", md: "12px" },
              position: "fixed",
              bottom: 0,
              left: 0,
              zIndex: 1,
            }}
          >
            &copy; {new Date().getFullYear()} SedraPool |{" "}
            <span
              onClick={() => setOpenTermsModal(true)}
              style={{ cursor: "pointer", textDecoration: "underline", color: "#00BFFF" }}
            >
              Terms of Service
            </span>
          </Box>
        )}

        {/* Terms of Service Modal */}
        <TermsOfServiceModal open={openTermsModal} onClose={() => setOpenTermsModal(false)} />
      </Box>
    </ThemeProvider>
  );
}
