import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from "@mui/material";

const TermsOfServiceModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6" fontWeight="bold">
          SedraPool - Terms of Service
        </Typography>
      </DialogTitle>

      <DialogContent dividers style={{ maxHeight: "500px", overflowY: "auto" }}>
        <Typography variant="body1" paragraph>
          <strong>Effective Date:</strong> 01/01/2025
        </Typography>

        <Typography variant="h6" fontWeight="bold">1. Preamble</Typography>
        <Typography variant="body2" paragraph>
          Welcome to SedraPool! These Terms and Conditions ("Terms") govern your use of
          SedraPool's mining pool services. By accessing and utilizing our services, you
          acknowledge and agree to comply with these Terms.
        </Typography>

        <Typography variant="h6" fontWeight="bold">6. Compliance with MiCA Regulations</Typography>
        <Typography variant="body2" paragraph>
          SedraPool does not offer staking services, including Staking-as-a-Service (StaaS), to its
          users. By not offering such services, SedraPool ensures compliance with MiCA regulations.
        </Typography>

        <Typography variant="h6" fontWeight="bold">9. Contact Information</Typography>
        <Typography variant="body2">
          For any inquiries regarding these Terms, contact us at:
          <br />
          📧 post@sedrapool.com
          <br />
          🌐 www.sedrapool.com
        </Typography>
      </DialogContent>

      {/* Close Button */}
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsOfServiceModal;
