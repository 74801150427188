import React, { useState, useEffect } from "react";

const BlockReward = () => {
  const [reward, setReward] = useState("Fetching...");
  const API = "https://sedrapool.com/api/blocks";
  const MAX_RETRIES = 3;

  // ✅ Fetch block reward from the SedraPool API
  const fetchBlockReward = async (attempt = 1) => {
    try {
      console.log(`🔄 Fetching block reward... (Attempt ${attempt})`);

      const response = await fetch(API);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const data = await response.json();
      console.log("🚀 Full API Response:", JSON.stringify(data, null, 2));

      if (!Array.isArray(data) || data.length === 0) {
        throw new Error("No blocks found in API response.");
      }

      // ✅ Get latest confirmed block
      let latestBlock = data.find(block => block.status === "confirmed") || data[0];

      console.log("✅ Latest Block Data:", latestBlock);

      if (!latestBlock || typeof latestBlock.reward !== "number" || latestBlock.reward === 0) {
        console.warn("⚠️ Reward is zero or missing, waiting for update...");
        throw new Error("Block reward not available yet.");
      }

      // ✅ Format reward
      const formattedReward = `${latestBlock.reward.toLocaleString()} SDR`;

      console.log("✅ Storing Block Reward in Cache:", formattedReward);

      // ✅ Store in localStorage (if available)
      try {
        localStorage.setItem("blockReward", formattedReward);
      } catch (err) {
        console.warn("⚠️ localStorage not available in this environment.");
      }

      setReward(formattedReward);
    } catch (error) {
      console.error("❌ Error fetching block reward:", error.message);

      if (attempt < MAX_RETRIES) {
        console.warn(`🔁 Retrying... (${attempt + 1}/${MAX_RETRIES})`);
        setTimeout(() => fetchBlockReward(attempt + 1), 3000);
      } else {
        console.warn("❌ Max retries reached. Using cached value if available.");

        let storedReward = null;
        try {
          storedReward = localStorage.getItem("blockReward");
        } catch (err) {
          console.warn("⚠️ localStorage access blocked in this environment.");
        }

        console.log("📦 Using cached block reward:", storedReward);
        setReward(storedReward && storedReward !== "undefined" ? storedReward : "Reward Unavailable");
      }
    }
  };

  // ✅ Load reward on component mount
  useEffect(() => {
    let storedReward = null;
    try {
      storedReward = localStorage.getItem("blockReward");
    } catch (err) {
      console.warn("⚠️ localStorage access blocked in this environment.");
    }

    console.log("🛠️ Checking stored Block Reward in Cache:", storedReward);

    if (storedReward && storedReward !== "undefined" && storedReward !== null) {
      setReward(storedReward);
    } else {
      fetchBlockReward(); // Fetch only if no valid cache exists
    }
  }, []);

  return <span>{reward}</span>;
};

export default BlockReward;
