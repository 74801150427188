import React from "react";
import { Box, Typography } from "@mui/material";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import { styled, width } from "@mui/system";

// 🎮 Logo Container (Aligns Content)
const LogoContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  textAlign: "left",
  userSelect: "none",
  position: "relative", // Needed for absolute positioning of the icons above "L"
});

// 🔥 Icons Positioned Above "L" in SEDRAPOOL (3 Icons in a Row)
const IconsRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0px", // Space between icons
  position: "absolute",
  left: "60%", // Default position
  bottom: "60%", // Default position

  // ✅ Responsive Positioning (Matches Original Single Icon)
  [theme.breakpoints.up("sm")]: { left: "66%", bottom: "45%", gap: "2px" },
  [theme.breakpoints.up("md")]: { left: "81%", bottom: "57%" },
  [theme.breakpoints.up("lg")]: { left: "85%", bottom: "64%" },
  [theme.breakpoints.up("xl")]: { left: "88%", bottom: "66%" },
  [theme.breakpoints.up("xxl")]: { left: "93%", bottom: "71%" },
}));

// 🌿 Original Leaf Icon (Now in Row with Flags)
const StyledIcon = styled(EnergySavingsLeafIcon)(({ theme }) => ({
  color: "#0ff",
  width: "20px",
  fontSize: "19px", // Default for xs

  [theme.breakpoints.up("sm")]: { fontSize: "24px", width: "40px" },
  [theme.breakpoints.up("md")]: { fontSize: "28px", width: "30px" },
  [theme.breakpoints.up("lg")]: { fontSize: "30px", width: "35px" },
  [theme.breakpoints.up("xl")]: { fontSize: "36px", width: "40px" },
  [theme.breakpoints.up("xxl")]: { fontSize: "42px", width: "40px" },
}));

// 🌿 Wrapper for Each Flag + Leaf (Exact Clipping Applied)
const LeafWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  width: "20px", // Default for xs
  height: "20px", 

  [theme.breakpoints.up("sm")]: { width: "40px", height: "40px" },
  [theme.breakpoints.up("md")]: { width: "30px", height: "30px" },
  [theme.breakpoints.up("lg")]: { width: "35px", height: "35px" },
  [theme.breakpoints.up("xl")]: { width: "40px", height: "40px" },
  [theme.breakpoints.up("xxl")]: { width: "40px", height: "40px" },
}));


// 🚩 Flag Styling (Futuristic Clip Effect, Same as Reference)
const FlagOverlay = styled("img")(({ theme }) => ({
  position: "absolute",
  width: "33%", // Default size
  height: "33%",
  objectFit: "cover",
  zIndex: 10,
  opacity: 0.9,

  // ✅ Keeps the futuristic clipped effect
  clipPath: "polygon(0% 0%, 50% 0%, 100% 0%, 100% 50%, 100% 100%, 50% 100%, 0% 100%, 0% 50%, 0% 0%, 0% 0%)",
  borderRadius: "50% 0 50% 50%",

  // ✅ Responsive Size Changes Based on Breakpoints
  [theme.breakpoints.down("sm")]: { width: "62%", height: "62%" },
  [theme.breakpoints.up("md")]: { width: "57%", height: "57%" },
  [theme.breakpoints.up("lg")]: { width: "54%", height: "54%" },
  [theme.breakpoints.up("xl")]: { width: "60%", height: "60%"},

  [theme.breakpoints.up("xxl")]: {
    width: "70%", // ✅ XXL Breakpoint
    height: "70%",
  },
}));


// 📦 Flag Icons Component (EU & US, Keeping Original Clipping)
const FlagIcons = () => {
  return (
    <>
      <LeafWrapper>
        <FlagOverlay src="https://flagcdn.com/w40/eu.png" alt="EU Flag" />
        <StyledIcon />
      </LeafWrapper>
      <LeafWrapper>
        <FlagOverlay src="https://flagcdn.com/w40/us.png" alt="US Flag" />
        <StyledIcon />
      </LeafWrapper>
    </>
  );
};

// 💠 Responsive Title (SEDRAPOOL)
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "900",
  fontFamily: "'Russo One', sans-serif",
  letterSpacing: "-2px",
  color: "#fff",
  fontSize: "1.4rem", // Default for xs
  [theme.breakpoints.up("sm")]: { fontSize: "1.6rem" },
  [theme.breakpoints.up("md")]: { fontSize: "2rem" },
  [theme.breakpoints.up("lg")]: { fontSize: "2.4rem" },
  [theme.breakpoints.up("xl")]: { fontSize: "2.8rem" },
  [theme.breakpoints.up("xxl")]: { fontSize: "4rem" },
}));

// 🎯 Tagline (Subtitle)
const StyledTagline = styled(Typography)(({ theme }) => ({
  fontWeight: "normal",
  fontStyle: "italic",
  fontFamily: "'Russo One', sans-serif",
  color: "#c0c0c0",
  fontSize: "0.50rem", // Default for xs
  [theme.breakpoints.up("sm")]: { fontSize: "0.6rem" },
  [theme.breakpoints.up("md")]: { fontSize: "0.6rem" },
  [theme.breakpoints.up("lg")]: { fontSize: "0.7rem" },
  [theme.breakpoints.up("xl")]: { fontSize: "0.8rem" },
  [theme.breakpoints.up("xxl")]: { fontSize: "1.1rem" },
}));

// 🎯 **Final Logo Component (WITH 3 Icons Above "L" and Correct Clipping)**
const Logo = () => {
  return (
    <LogoContainer>
      {/* ✅ 3 Icons in a Row Positioned Above the "L" */}
      <IconsRow>
        <StyledIcon /> {/* Original Main Icon */}
        <FlagIcons /> {/* Flag Icons (EU & US) */}
      </IconsRow>

      {/* ✅ Text Content Below the Icons */}
      <StyledTitle>SEDRAPOOL</StyledTitle>
      <StyledTagline>The world could always use more Sedrians</StyledTagline>
    </LogoContainer>
  );
};

export default Logo;
