import React from "react";
import "./NFTCube.css"; // Import CSS

const NFTCube = ({ title, progress }) => {
  return (
    <div className="NFT-box">
      <div className="nft-inner">
        <div className="nft-cube">
          {/* Right Face */}
          <div className={`nft-cube-right ${progress >= 100 ? "glow" : ""}`}>
            <div className="right-image-box"></div>
            <div 
              className="nft-right-overlay"
              style={{ 
                width: `${Math.min(Math.max((progress - 50) * 2, 0), 100)}%`, /* Prevent overflow */
                opacity: progress >= 50 ? 1 : 0  /* Hide until left is 100% */
              }}
            ></div>
          </div>
          {/* Left Face */}
          <div className={`nft-cube-left ${progress >= 100 ? "glow" : ""}`}>
            <div className="left-image-box"></div>
            <div 
              className="nft-left-overlay"
              style={{ width: `${Math.min(progress * 2, 100)}%` }} /* Fills up to 100% within first 50% */
            ></div>
          </div>
        </div>
      </div>
      <p className="nft-title">{title}</p>
    </div>
  );
};

export default NFTCube;
