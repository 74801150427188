import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// ✅ Styling
const StyledCard = styled(Card)({
  backgroundColor: "#1b1f23",
  color: "white",
  borderRadius: "8px",
  boxShadow: "0px 3px 10px rgba(255, 255, 255, 0.1)",
  padding: "10px",
  textAlign: "center",
  minWidth: "260px",
});

const StyledTableContainer = styled(TableContainer)({
  backgroundColor: "#25282f",
  borderRadius: "8px",
  boxShadow: "none",
  maxHeight: "360px",
  overflowY: "auto",
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: "#2d323a",
});

const StyledTableCell = styled(TableCell)({
  color: "white",
  borderBottom: "1px solid #444",
  textAlign: "center",
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: "13px",
  padding: "6px 8px",
});

const StyledTableHeaderCell = styled(StyledTableCell)({
  fontWeight: "bold",
});

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": { backgroundColor: "#2a2e35" },
  "&:nth-of-type(even)": { backgroundColor: "#202329" },
});

const RankCell = styled(TableCell)({
  fontSize: "14px",
  fontWeight: "bold",
  borderRadius: "8px",
  textAlign: "center",
});

const TopMinersTable = ({ poolType = "pplns" }) => {
  const [summedMiners, setSummedMiners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // ✅ API URL
  const getSummedApiUrl = (type) =>
    type === "solo"
      ? "https://sedrapool.com/api/ext/sdr-solo/hashrates?limit=100"
      : "https://sedrapool.com/api/ext/sdr/hashrates?limit=100";

  useEffect(() => {
    const fetchSummedMiners = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await fetch(getSummedApiUrl(poolType));
        if (!response.ok) throw new Error(`API Error: ${response.status}`);

        const data = await response.json();
        if (data.data) {
          // ✅ Convert hashrate to TH/s correctly (divide by 1e16)
          const processedMiners = data.data.map((miner) => ({
            miner: miner.miner,
            hashrateRaw: Number(miner.hashrate), // Keep original raw value for dynamic conversion
          }));

          console.log("✅ FIXED Summed Miners (Final TH/s Fix):", processedMiners);
          setSummedMiners(processedMiners);
        }
      } catch (err) {
        console.error("⚠️ API Fetch Error:", err.message);
        setError("Error fetching summed miners.");
        setSummedMiners([]);
      } finally {
        setLoading(false);
      }
    };

    fetchSummedMiners();
    const interval = setInterval(() => {
      fetchSummedMiners();
    }, 300000);
    return () => clearInterval(interval);
  }, [poolType]);

  // ✅ Function to dynamically format hashrate
  const formatHashrate = (hashrate) => {
    const hashrateTH = hashrate / 1e16; // Convert to TH/s
    if (hashrateTH < 1.0) {
      return `${(hashrateTH * 1000).toFixed(1)} GH/s`; // Convert to GH/s when TH/s < 1.0
    }
    return `${hashrateTH.toFixed(1)} TH/s`;
  };

  return (
    <StyledCard>
      <CardContent sx={{ padding: "10px" }}>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
            fontFamily: "'Russo One', sans-serif",
            textTransform: "uppercase",
            textAlign: "left",
          }}
        >
          Top {poolType.toUpperCase()} Miners
        </Typography>

        {loading ? (
          <CircularProgress sx={{ color: "white", marginTop: "10px" }} size={18} />
        ) : error ? (
          <Typography color="error" sx={{ fontSize: "12px" }}>{error}</Typography>
        ) : (
          <StyledTableContainer component={Paper}>
            <Table size="small">
              <StyledTableHead>
                <TableRow>
                  <StyledTableHeaderCell>Rank</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Miner</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Hashrate</StyledTableHeaderCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {summedMiners.map((miner, index) => (
                  <StyledTableRow key={index}>
                    <RankCell>{index + 1}</RankCell> {/* Rank Column */}
                    <StyledTableCell sx={{ fontSize: "11px" }}>
                      {miner.miner.substring(0, 6)}&hellip;{miner.miner.slice(-6)}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontSize: "11px" }}>
                      {formatHashrate(miner.hashrateRaw)} {/* ✅ TH/s or GH/s */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default TopMinersTable;
