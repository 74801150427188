import React, { useState, useEffect } from "react";

const API = "https://sedrapool.com/api/pools"; // Replace with your actual API URL

const MinersCount = ({ type = "both" }) => {
  const [pplnsMiners, setPplnsMiners] = useState(0);
  const [soloMiners, setSoloMiners] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMinersCount = async () => {
      try {
        const response = await fetch(API);
        const data = await response.json();

        let pplns = 0;
        let solo = 0;

        data.pools.forEach((pool) => {
          if (pool.paymentProcessing.payoutScheme === "PPLNS") {
            pplns = pool.poolStats.connectedMiners;
          } else if (pool.paymentProcessing.payoutScheme === "SOLO") {
            solo = pool.poolStats.connectedMiners;
          }
        });

        setPplnsMiners(pplns);
        setSoloMiners(solo);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching miners count:", error);
        setLoading(false);
      }
    };

    fetchMinersCount();
    const interval = setInterval(fetchMinersCount, 30000); // Auto-refresh every 30 sec

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <span className="miners-container">
      {loading ? (
        <span>Loading...</span>
      ) : (
        <>
          {type === "both" || type === "pplns" ? (
            <span>{pplnsMiners}</span> // ✅ Changed <div> to <span>
          ) : null}
          {type === "both" || type === "solo" ? (
            <span>{soloMiners}</span> // ✅ Changed <div> to <span>
          ) : null}
        </>
      )}
    </span>
  );
  
};

export default MinersCount;
