import React, { useState, useEffect } from "react";
import NFTCube from "./NFTCube"; // Import NFTCube Component
import TotalBlocks from "./TotalBlocks";
import { styled } from "@mui/material/styles";
import { Box, Typography, Fade } from "@mui/material";
import "../App.css"; // Import CSS for animations

// ✅ Gamified HUD Container (Now Responsive)
const BlockDiscoveryContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    borderRadius: "4px",
    position: "relative",
    height: "95%",

    // ✅ Responsive Styles (Mobile Adjustments)
    [theme.breakpoints.down("sm")]: {
        background: "rgba(000, 000, 000, 0.1)",
        top: "130px",
    },
}));
// ✅ Gamified Title
const CubeTitle = styled(Typography)({
    fontSize: "0.8rem",
    fontWeight: "bold",
    textAlign: "center",
    color: "#00eaff",
    textShadow: "0px 0px 8px rgba(0, 255, 135, 0.9)",
    fontFamily: "'Press Start 2P', sans-serif",
    margin: "0",
});

// ✅ Gamified Title
const CubeSubTitle = styled(Typography)({
    fontSize: "0.65rem",
    fontWeight: "normal",
    textAlign: "center",
    color: "#00eaff",
    textShadow: "0px 0px 8px rgba(0, 255, 135, 0.9)",
    fontFamily: "'Press Start 2P', sans-serif",
    marginBottom: "5px",
});

// ✅ SOLVED Explosion Effect
const SolvedExplosion = styled(Box)({
    position: "absolute",
    width: "120px",
    height: "120px",
    background: "radial-gradient(circle, rgba(255, 255, 0, 0.8) 0%, rgba(255, 0, 0, 0) 80%)",
    filter: "blur(8px)",
    borderRadius: "50%",
    animation: "explode 0.5s ease-out",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    opacity: 0,
    zIndex: 1,
});

// ✅ "SOLVED" Message
const SolvedMessage = styled(Typography)({
    fontSize: "0.9rem",
    fontWeight: "bold",
    color: "#ffcc00",
    textShadow: "0px 0px 15px rgba(255, 204, 0, 0.9)",
    fontFamily: "'Press Start 2P', sans-serif",
    marginTop: "8px",
});

// ✅ Reward Message
const RewardMessage = styled(Typography)({
    fontSize: "0.8rem",
    color: "#ffffff",
    textShadow: "0px 0px 5px rgba(0, 255, 135, 0.7)",
    fontFamily: "'Press Start 2P', sans-serif",
    marginTop: "0px",
    opacity: 0.8,
});

const BlockDiscovery = () => {
    const [progress, setProgress] = useState(0);
    const [displayProgress, setDisplayProgress] = useState(0);
    const [poolBlocks, setPoolBlocks] = useState(null);
    const [blockSolved, setBlockSolved] = useState(false);
    const [reward, setReward] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://sedrapool.com/api/pools/sdr");
                if (!response.ok) throw new Error("Failed to fetch block data.");
                const data = await response.json();

                const poolEffort = data.pool.poolEffort;
                const latestPoolBlocks = data.pool.totalBlocks;
                const progressPercentage = parseFloat((poolEffort * 100).toFixed(2));
                const blockReward = data.pool.reward || 311; // ✅ Default to 331 SDR if undefined

                //console.log("Fetched Block Data:", data);

                // ✅ Block Solved - Explosion Effect
                if (poolBlocks !== null && latestPoolBlocks > poolBlocks) {
                    setBlockSolved(true);
                    setReward(blockReward);
                    setTimeout(() => setBlockSolved(false), 3000); // ✅ Show for 3 seconds

                    // ✅ Reset progress to 0 when block is solved
                    setProgress(0);
                    setDisplayProgress(0);
                    console.log(`🎉 Block Solved! ${blockReward} SDR rewarded.`);
                } else {
                    if (progressPercentage !== progress) {
                        setProgress(progressPercentage);
                        console.log(`📊 Updated Progress: ${progressPercentage}%`);
                    }
                }

                setPoolBlocks(latestPoolBlocks);
            } catch (error) {
                console.error("❌ Error fetching block data:", error);
            }
        };

        fetchData();
        const interval = setInterval(fetchData, 10000);
        return () => clearInterval(interval);
    }, [poolBlocks]);

    // ✅ Smoother Progress Animation
    useEffect(() => {
        const step = () => {
            setDisplayProgress((prev) => {
                if (prev < progress) return prev + 0.1;
                if (prev > progress) return prev - 0.1;
                return progress;
            });
        };

        const animation = setInterval(step, 50);
        return () => clearInterval(animation);
    }, [progress]);

    return (
        <BlockDiscoveryContainer>
            <TotalBlocks />
            <CubeTitle>Live Tracking</CubeTitle>
            <CubeSubTitle> SedraPool miners solving the current Block</CubeSubTitle>
            <div className="nft-cube-container" style={{ position: "relative", width: "90px", height: "90px" }}>
                {/* ✅ SOLVED EXPLOSION */}
                <Fade in={blockSolved}>
                    <SolvedExplosion />
                </Fade>
                
                {/* ✅ NFT Cube */}
                <NFTCube
                    title={`${Math.round(displayProgress)}% effort`}
                    progress={displayProgress}
                />

            </div>

            {/* ✅ "SOLVED" + Reward Message */}
            <Fade in={blockSolved} timeout={500}>
                <SolvedMessage>SOLVED</SolvedMessage>
            </Fade>
            <Fade in={blockSolved} timeout={1000}>
                <RewardMessage>{reward} SDR rewarded to miners</RewardMessage>
            </Fade>
        </BlockDiscoveryContainer>
    );
};

export default BlockDiscovery;
