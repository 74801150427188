import { useState } from "react";
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from "@mui/material";

const SustainabilityPopup = () => {
  const [open, setOpen] = useState(false);
  const [selectedWord, setSelectedWord] = useState("");

  // Messages for each clickable label
  const messages = {
    hashrate: "Sedrapool miners are planting trees with their hashrate, thereby inspiring others to follow their act, and spreading the circle of nurturing the environment.",
    world: "Proof of Impact: Tree Planting Certificates for Miners! 🌍 Each tree planted through SedraPool comes with a certificate showing its location. As a miner, you'll be able to access certificates for the trees your hashrate helped plant, offering transparent proof of your environmental impact. 🌳 Tree planting rewards will also appear on your dashboard, so you can track both your mining performance and your contribution to a greener future.",
    contribution: "Trees absorb carbon dioxide and are vital carbon sinks. A large tree inhales around 20.3 kgs of CO2 in a year and exhales enough oxygen for a family of four for a year.",
    greener: "137 different species of plants & animals are lost every single day due to global forest loss. Global CO₂ emissions have increased by more than 60%. And one of the primary reasons is the reduction in tree coverage.",
  };

  // Open the modal with the selected label
  const handleClick = (word) => {
    setSelectedWord(word);
    setOpen(true);
  };

  return (
    <>
      {/* 🌍 Styled Sustainability Message */}
      <Box
        sx={{
          fontFamily: "BigNoodleTitling",
          fontSize: { xs: "0.6rem", sm: "0.9rem", md: "1.0rem", lg: "1.3rem", xl: "1.5rem", xxl: "2.4rem" },
          color: "#fff",
          padding: { xs: "2px", sm: "10px", md: "12px" },
          lineHeight: "1.1", // ✅ Keeps spacing tight but readable
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          gap: "5px", // ✅ Ensures even spacing between words
        }}
      >
        <Box component="span">Your Sedra</Box>{" "}
        <Box
          component="span"
          sx={{
            fontFamily: "BigNoodleTitling",
            color: "#32e0c4",
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "inherit",
            whiteSpace: "nowrap",
            "&:hover": { color: "#3affd4" },
          }}
          onClick={() => handleClick("hashrate")}
        >
          hashrate
        </Box>{" "}
        <Box component="span">is a direct</Box>{" "}
        <Box
          component="span"
          sx={{
            fontFamily: "BigNoodleTitling",
            color: "#32e0a0",
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "inherit",
            whiteSpace: "nowrap",
            "&:hover": { color: "#3affc0" },
          }}
          onClick={() => handleClick("contribution")}
        >
          contribution
        </Box>{" "}
        <Box component="span">to a</Box>{" "}
        <Box
          component="span"
          sx={{
            fontFamily: "BigNoodleTitling",
            color: "#b6deb0",
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "inherit",
            whiteSpace: "nowrap",
            "&:hover": { color: "#c6ffbe" },
          }}
          onClick={() => handleClick("greener")}
        >
          greener
        </Box>{" "}
        <Box component="span">blockchain &</Box>{" "}
        <Box
          component="span"
          sx={{
            fontFamily: "BigNoodleTitling",
            color: "#b6deb0",
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "inherit",
            whiteSpace: "nowrap",
            "&:hover": { color: "#c6ffbe" },
          }}
          onClick={() => handleClick("world")}
        >
          the world🔗
        </Box>
      </Box>

      {/* 📜 Dialog Popup */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            width: { xs: "90%", sm: "70%", md: "60%", lg: "50%", xl: "40%" }, // Responsive width
            padding: { xs: "0", sm: "20px", md: "24px" },
            textAlign: "center",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: "BigNoodleTitling",
            fontSize: { xs: "1.5rem", sm: "1.7rem", md: "1.8rem", lg: "2rem", xl: "2.2rem" },
            color: "#32e0c4",
            textAlign: "center",
          }}
        >
          {selectedWord.toUpperCase()}
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontFamily: "BigNoodleTitling",
              fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem", lg: "1.6rem", xl: "1.8rem" },
              color: "#fff",
              textAlign: "center",
              padding: { xs: "6px", sm: "8px", md: "10px" },
              lineHeight: "1.3",
            }}
          >
            {messages[selectedWord]}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            sx={{
              fontFamily: "BigNoodleTitling",
              fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem", lg: "1.3rem", xl: "1.4rem" },
              color: "#32e0c4",
              width: "100%",
              "&:hover": { backgroundColor: "rgba(50, 224, 196, 0.2)" },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SustainabilityPopup;
