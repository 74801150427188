import React, { useState, useEffect } from "react";

const TotalBlocks = ({ onUpdate }) => {
    const [latestPoolBlocks, setLatestPoolBlocks] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://sedrapool.com/api/pools/sdr");
                if (!response.ok) throw new Error("Failed to fetch block data.");
                const data = await response.json();
    
                const newPoolBlocks = data.pool.totalBlocks;
    
                // ✅ Update only if the block count actually changed
                setLatestPoolBlocks((prevBlocks) => {
                    if (newPoolBlocks !== prevBlocks) {
                        console.log("📊 New Block Data:", newPoolBlocks); // ✅ Logs only if there's a change
                        if (onUpdate) onUpdate(newPoolBlocks);
                        return newPoolBlocks;
                    }
                    return prevBlocks; // ✅ Keeps the old value if nothing changed
                });
            } catch (error) {
                console.error("❌ Error fetching block data:", error);
            }
        };
    
        fetchData();
        const interval = setInterval(fetchData, 30000); // ✅ 30 sec interval to reduce API calls
        return () => clearInterval(interval);
    }, [onUpdate]); // ✅ Only re-run if `onUpdate` changes    

    return null; // ✅ No direct rendering needed
};

export default TotalBlocks;
