import React, { useEffect, useState } from "react";

const PoolHashrate = ({ type = "pplns", average = false }) => {
  const [hashrate, setHashrate] = useState(null);

  useEffect(() => {
    const fetchHashrate = async () => {
      try {
        const apiUrl =
          type === "solo"
            ? "https://sedrapool.com/api/pools/sdr-solo"
            : "https://sedrapool.com/api/pools/sdr";

        const response = await fetch(apiUrl);
        const data = await response.json();

        let formattedHashrate;

        if (average) {
          // Fetch performance data for average hashrate
          const perfResponse = await fetch(`${apiUrl}/performance`);
          const perfData = await perfResponse.json();
          
          if (perfData.stats.length > 0) {
            // Extract pool hashrates from performance data
            const hashrates = perfData.stats.map(stat => stat.poolHashrate);
            const avgHashrate = hashrates.reduce((acc, val) => acc + val, 0) / hashrates.length;
            formattedHashrate = (avgHashrate / 1e12).toFixed(2) + " TH/s";
          } else {
            formattedHashrate = "No data";
          }
        } else {
          // Current Hashrate
          formattedHashrate = (data.pool.poolStats.poolHashrate / 1e12).toFixed(2) + " TH/s";
        }

        setHashrate(formattedHashrate);
      } catch (error) {
        console.error("Error fetching hashrate:", error);
      }
    };

    fetchHashrate();
    const interval = setInterval(fetchHashrate, 5000); // Refresh every 5s

    return () => clearInterval(interval); // Cleanup on unmount
  }, [type, average]); // Re-run effect when `type` or `average` changes

  return (
    <div className="stat-value">
      {hashrate ? hashrate : "Loading..."}
    </div>
  );
};

export default PoolHashrate;
