import React, { useEffect, useState } from "react";

const AvgPoolEffort = ({ type = "pplns", blocksToAverage = 10 }) => {
  const [effort, setEffort] = useState("Loading...");

  useEffect(() => {
    const fetchEffort = async () => {
      try {
        // ✅ Determine correct API endpoint
        const apiUrl =
          type === "solo"
            ? "https://sedrapool.com/api/pools/sdr-solo/blocks"
            : "https://sedrapool.com/api/pools/sdr/blocks";

        const response = await fetch(apiUrl);
        const data = await response.json();

        // ✅ Validate response structure
        if (!data || !Array.isArray(data) || data.length === 0) {
          setEffort("No Data");
          return;
        }

        // ✅ Extract and filter valid effort values
        const efforts = data
          .filter(block => block.effort !== undefined) // Remove blocks without effort
          .slice(0, blocksToAverage) // Take latest X blocks
          .map(block => block.effort);

        // ✅ Ensure efforts exist before calculating
        if (efforts.length === 0) {
          setEffort("No Blocks");
          return;
        }

        // ✅ Compute the average effort and format as percentage
        const avgEffort = (
          (efforts.reduce((sum, e) => sum + e, 0) / efforts.length) * 100
        ).toFixed(2) + "%";

        setEffort(avgEffort);
      } catch (error) {
        console.error("Error fetching pool effort:", error);
        setEffort("Error");
      }
    };

    fetchEffort();
    const interval = setInterval(fetchEffort, 30000); // Refresh every 30s

    return () => clearInterval(interval);
  }, [type, blocksToAverage]);

  return <div className="stat-value">{effort}</div>;
};

export default AvgPoolEffort;
