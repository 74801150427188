import React, { useState } from "react";
import { Box, Typography, keyframes } from "@mui/material";
import { styled } from "@mui/system";
import MinersCount from "./MinersCount";

// 🚀 Pulse Animation for Epic Glow
const pulseGlow = keyframes`
  0% { box-shadow: 0px 0px 15px rgba(255, 255, 0, 0.8); }
  50% { box-shadow: 0px 0px 25px rgba(255, 165, 0, 1); }
  100% { box-shadow: 0px 0px 15px rgba(255, 255, 0, 0.8); }
`;

// 🎮 Styled HUD Card - Overwatch/Fortnite Style
const StyledCard = styled(Box)(({ selected }) => ({
  width: "100%",
  height: `calc(50% - 5px)`, // Adjusted for spacing
  position: "relative",
  overflow: "hidden",
  borderRadius: "16px",
  cursor: "pointer",
  transition: "transform 0.2s ease, box-shadow 0.3s ease, border 0.2s ease",
  border: selected
    ? "4px solid rgba(255, 215, 0, 0.9)"
    : "4px solid rgba(40, 40, 50, 1)",
  background: "linear-gradient(135deg, rgba(25, 25, 25, 1) 0%, rgba(40, 40, 50, 1) 100%)",
  boxShadow: selected
    ? "0px 0px 20px rgba(255, 215, 0, 0.9)"
    : "0px 4px 12px rgba(0, 0, 0, 0.5)",
  transform: selected ? "scale(1.02)" : "scale(1)",
  "&:hover": {
    transform: "scale(1.02)",
    animation: `${pulseGlow} 1.5s infinite alternate`,
  },
}));

// 🎨 Gradient Overlay for Text Readability
const StyledGradient = styled(Box)({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "50%",
  background: "linear-gradient(to top, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0))",
  zIndex: 1,
});

// ⚡ High-Energy Styled Text Container
const StyledTextContainer = styled(Box)({
  position: "absolute",
  bottom: "15px",
  left: "50%",
  transform: "translateX(-50%)",
  width: "100%",
  textAlign: "center",
  zIndex: 2,
  fontFamily: "'Orbitron', sans-serif",
});

// 🖥️ HUD Cards Component
const HUDCards = ({ showPPLNS, showSOLO, handleCardClick, hudHeight }) => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleClick = (type) => {
    setSelectedCard(type === selectedCard ? null : type);
    handleCardClick(type);
  };

  const cards = [
    { label: "PPLNS MODE - Earn Together!", type: "pplns", video: "/avatars/miners.mp4" },
    { label: "SOLO MODE - Go for Glory!", type: "solo", video: "/avatars/caretaker.mp4" },
  ];

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      height={hudHeight} 
      width="100%"
      gap="12px" // ✅ Adds epic spacing between cards
      boxSizing="border-box"
    >
      {cards.map(({ label, type, video }) => (
        <StyledCard
          key={type}
          selected={selectedCard === type}
          onClick={() => handleClick(type)}
        >
          <video
            src={video}
            autoPlay
            loop
            muted
            playsInline
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              filter: "brightness(115%) contrast(130%)",
            }}
          />

          {/* Gradient Overlay for Text Readability */}
          <StyledGradient />

          {/* Text Overlay */}
          <StyledTextContainer>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "900",
              textTransform: "uppercase",
              padding: "0 5px",
              color: "#FFD700",
              textShadow: "0px 0px 15px rgba(255, 165, 0, 1), 2px 2px 0px rgba(0, 0, 0, 1)",
              letterSpacing: "2px",
              fontSize: { xs: "0.55rem", sm: "0.9rem", md: "1.0rem", lg: "1.1rem", xl: "1.2rem", xxl: "1.8rem" },
              marginBottom: { xs: "4px", sm: "6px", md: "8px", lg: "10px", xl: "12px", xxl: "14px" },
            }}
          >
            {label}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              padding: "0 5px",
              color: "#FFFFFF",
              textShadow: "0px 0px 10px rgba(0, 255, 255, 1)",
              letterSpacing: "1px",
              fontSize: { xs: "0.4rem", sm: "0.8rem", md: "0.8rem", lg: "0.8rem", xl: "0.9rem", xxl: "1.3rem" },
              marginTop: { xs: "2px", sm: "4px", md: "6px", lg: "8px", xl: "10px", xxl: "12px" }, // ✅ Adjust spacing
            }}
          >
            MINERS ACTIVE: <MinersCount type={type} />
          </Typography>

          </StyledTextContainer>
        </StyledCard>
      ))}
    </Box>
  );
};

export default HUDCards;
