import React, { useState, useEffect } from "react";

const NetworkHashrate = () => {
  const [hashrate, setHashrate] = useState("Fetching...");
  const API = "https://sedrapool.com/api/pools/sdr";
  const MAX_RETRIES = 3;

  const fetchNetworkHashrate = async (attempt = 1) => {
    try {
      console.log(`Fetching network hashrate... (Attempt ${attempt})`);
      const response = await fetch(API);
      if (!response.ok) throw new Error(`Failed to fetch (HTTP ${response.status})`);

      const data = await response.json();
      console.log("API Response:", data);

      // ✅ Extract network hashrate from the correct path
      const networkHashrate = data.pool?.networkStats?.networkHashrate;

      if (!networkHashrate || typeof networkHashrate !== "number") {
        throw new Error("Network hashrate is missing or invalid");
      }

      // ✅ Convert H/s → PH/s (PetaHashes per second)
      const hashratePHs = (networkHashrate / 1e15).toFixed(2);
      console.log(`✅ Converted Network Hashrate: ${hashratePHs} PH/s`);

      setHashrate(`${hashratePHs} PH/s`);
    } catch (error) {
      console.error("Error fetching network hashrate:", error.message);

      if (attempt < MAX_RETRIES) {
        console.warn(`Retrying... (${attempt + 1}/${MAX_RETRIES})`);
        setTimeout(() => fetchNetworkHashrate(attempt + 1), 2000);
      } else {
        setHashrate("Error");
      }
    }
  };

  useEffect(() => {
    fetchNetworkHashrate(); // ✅ Load once on mount

    const interval = setInterval(fetchNetworkHashrate, 600000); // ✅ Update every 10 min

    return () => clearInterval(interval); // ✅ Cleanup on unmount
  }, []);

  return <span>{hashrate}</span>;
};

export default NetworkHashrate;
