import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  useMediaQuery
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { styled, useTheme } from "@mui/material/styles";

const pplnsApiUrl = "https://sedrapool.com/api/ext/sdr/hashrates?page=1&limit=10";
const soloApiUrl = "https://sedrapool.com/api/ext/sdr-solo/hashrates?page=1&limit=10";

// 🎮 Responsive Scoreboard Container

const ProgressBar = styled(Box)(({ width }) => ({
  position: "absolute",
  left: 0,
  top: 0,
  height: "100%",
  width: `${width}%`,
  background: "linear-gradient(to left, rgba(50, 224, 196, 1), rgba(144, 238, 144, 0.2))",
  opacity: 0.85,
  borderRadius: "6px 0 0 6px",
  zIndex: 0,
}));

// 🎮 Scroll Controls
const ScrollControls = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  padding: "8px",
});

const ScoreboardContainer = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: "550px",
  height: `calc(100dvh - var(--computed-header-height) - 20px)`,
  minHeight: "450px",
  maxHeight: `calc(100dvh - var(--computed-header-height))`,
  borderRadius: "12px",
  overflow: "hidden",
  background: "rgba(10, 25, 40, 0.6)",
  backdropFilter: "blur(10px)",
  boxShadow: "0px 4px 16px rgba(0, 255, 170, 0.3)",
  display: "flex",
  flexDirection: "column",
  border: "2px solid rgba(0, 255, 170, 0.2)",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    minHeight: "320px",
  },
}));

const ScoreboardList = styled(Box)({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  overflowX: "hidden",
  scrollbarWidth: "none", // Firefox
  "-ms-overflow-style": "none", // IE & Edge
  "&::-webkit-scrollbar": {
    display: "none", // Chrome, Safari, Edge
  },
});

const PlayerItem = styled(Box)({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  background: "rgba(30, 50, 80, 0.4)",
  border: "1.5px solid rgba(0, 255, 170, 0.3)",
  borderRadius: "8px",
  padding: "10px",
  marginBottom: "5px",
  transition: "0.3s",
  "&:hover": {
    boxShadow: "0px 0px 10px rgba(0, 255, 170, 0.5)",
    transform: "scale(1.01)",
  },
});

const Scoreboard = () => {
  const [miners, setMiners] = useState([]);
  const [loading, setLoading] = useState(true);
  const scoreboardRef = useRef(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchMinerData();
  }, []);

  const fetchMinerData = async () => {
    try {
      const [pplnsRes, soloRes] = await Promise.all([
        fetch(pplnsApiUrl).then((res) => res.json()),
        fetch(soloApiUrl).then((res) => res.json()),
      ]);

      const totalHashrate = (pplnsRes.meta?.totalHashrate || 0) + (soloRes.meta?.totalHashrate || 0);

      const mergedMiners = [
        ...(pplnsRes.data || []).map((miner) => ({ ...miner, type: "PPLNS" })),
        ...(soloRes.data || []).map((miner) => ({ ...miner, type: "SOLO" })),
      ];

      const sortedMiners = mergedMiners
        .filter((m) => m.hashrate)
        .sort((a, b) => b.hashrate - a.hashrate)
        .slice(0, 15)
        .map((miner, index) => ({
          rank: index + 1,
          wallet: formatWallet(miner.miner),
          hashrate: formatHashrate(miner.hashrate),
          percentage: totalHashrate ? Math.round((miner.hashrate / totalHashrate) * 100) : 0,
          type: miner.type,
        }));

      setMiners(sortedMiners);
    } catch (error) {
      console.error("Error fetching miner data:", error);
    } finally {
      setLoading(false);
    }
  };

  // 🔹 Ensure Scroll Works
  const scroll = (direction) => {
    if (scoreboardRef.current) {
      scoreboardRef.current.scrollBy({
        top: direction === "down" ? (isSmallScreen ? 60 : 100) : (isSmallScreen ? -60 : -100),
        behavior: "smooth",
      });
    }
  };

  return (
    <ScoreboardContainer>
      <CardContent sx={{ flexShrink: 0, paddingBottom: "0px" }}>
        <Typography variant="h6" align="center" sx={{ fontWeight: "bold", fontSize: { xs: "1rem", sm: "1.2rem" } }}>
          Top Individual Miner
        </Typography>
        <Typography variant="subtitle2" align="center" sx={{ opacity: 0.8 }}>
          Top Single Hashrate Contributor
        </Typography>
      </CardContent>

      {/* Scoreboard List */}
      {loading ? (
        <Box textAlign="center" mt={2} flexGrow={1}>
          <CircularProgress />
        </Box>
      ) : (
        <ScoreboardList ref={scoreboardRef}>
          {miners.length === 0 ? (
            <Typography align="center" sx={{ opacity: 0.8 }}>
              No data available
            </Typography>
          ) : (
            miners.map((miner) => (
              <PlayerItem key={miner.rank}>
                <ProgressBar width={miner.percentage} />
                <Box sx={{ flexGrow: 1, position: "relative", zIndex: 1 }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "0.8rem", sm: "0.9rem" },
                    fontWeight: "bold",
                    pl: "12px", // Offset text to the right
                  }}
                >
                  #{miner.rank} {miner.wallet}{" "}
                  {miner.type === "SOLO" && (
                    <span style={{ color: "#90ee90", fontWeight: "bold", fontSize: "0.75rem" }}>
                      [SOLO]
                    </span>
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    opacity: 0.8,
                    fontSize: "0.7rem",
                    pl: "12px", // Offset hashrate text to the right
                  }}
                >
                  {miner.hashrate}
                </Typography>
                </Box>
                <Tooltip title="Miner's contribution to total hashrate">
                <Typography
                  variant="body2"
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: 10,
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    color: "#90ee90",
                    zIndex: 2,
                  }}
                >
                  {miner.percentage}%
                </Typography>
              </Tooltip>
              </PlayerItem>
            ))
          )}
        </ScoreboardList>
      )}

      <ScrollControls>
        <IconButton onClick={() => scroll("up")} sx={{ color: "#62A2EB" }}>
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton onClick={() => scroll("down")} sx={{ color: "#62A2EB" }}>
          <ArrowDownwardIcon />
        </IconButton>
      </ScrollControls>
    </ScoreboardContainer>
  );
};

const formatWallet = (wallet) => `${wallet.slice(0, 10)}...${wallet.slice(-4)}`;
const formatHashrate = (hashrate) => `${Math.round(hashrate / 1e12)} TH`;

export default Scoreboard;
