import { useState, useEffect } from "react";
import { Box, Typography, LinearProgress, useMediaQuery } from "@mui/material";
import ForestIcon from "@mui/icons-material/Forest";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import Co2Icon from "@mui/icons-material/Co2";

const API_URL = "https://sedrapool.com/api/ext/sdr/blocks/period/ebd1cf7d-0451-4a33-bacb-0be8afa7cf42";
const POLL_INTERVAL = 30000;

const CountdownTrees = () => {
  const [totalBatches, setTotalBatches] = useState(0);
  const [currentBatchSize, setCurrentBatchSize] = useState(0);
  const [blocksUntilNextTree, setBlocksUntilNextTree] = useState(1000);
  const [totalBlocks, setTotalBlocks] = useState("Loading...");
  const [co2Saved, setCo2Saved] = useState("Loading...");
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const fetchBatchData = async () => {
      try {
        const response = await fetch(API_URL);
        if (!response.ok) throw new Error("Failed to fetch batch data");

        const data = await response.json();
        if (!data.batches || data.batches.length === 0) return;

        const latestBatch = data.batches[0];
        const batchSize = data.batch_size || 1000;
        const completedBatches = data.totalBatches;

        setTotalBatches(completedBatches);
        setCurrentBatchSize(latestBatch.batch_size || 0);
        setBlocksUntilNextTree(batchSize - (latestBatch.batch_size || 0));
        setTotalBlocks(data.totalBlocksProcessed);
        setCo2Saved(data.totalBatches * 2.5);
      } catch (error) {
        console.error("Error fetching batch data:", error);
      }
    };

    fetchBatchData();
    const interval = setInterval(fetchBatchData, POLL_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  // Calculate progress percentage
  const progress = (currentBatchSize / 1000) * 100;

  // Function to determine progress bar color gradient
  const getProgressColor = () => {
    if (progress < 20) return "#00BFFF"; // Blue (Very Low progress)
    if (progress < 40) return "#1E90FF"; // DodgerBlue
    if (progress < 60) return "#32CD32"; // LimeGreen
    if (progress < 80) return "#3CB371"; // MediumSeaGreen
    return "#4CAF50"; // Green (High progress)
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: 400,
        margin: "auto",
      }}
    >
      {/* Progress Bar - Full Width */}
      <Box sx={{ width: "100%", mb: 2 }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: 12,
            borderRadius: 5,
            bgcolor: "#424242",
            "& .MuiLinearProgress-bar": { bgcolor: getProgressColor() },
          }}
        />
        <Typography variant="caption" sx={{ color: "#ffcc80", textAlign: "center", display: "block", mt: 0.5 }}>
          {blocksUntilNextTree} Blocks until next planting
        </Typography>
      </Box>

      {/* Stats Row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          gap: 2,
          background: "rgba(10, 25, 40, 0.6)",
          border: 2,
          borderColor: "rgba(0, 255, 170, 0.2)",
          borderRadius: 3,
          padding: 1, 
        }}
      >
        {/* Trees Planted */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <ForestIcon sx={{ color: "#90ee90", fontSize: isMobile ? 22 : 30 }} />
          <Box>
            <Typography variant={isMobile ? "body2" : "h6"} sx={{ color: "#fff", fontWeight: "bold" }}>
              {totalBatches}
            </Typography>
            <Typography variant="caption" sx={{ color: "#bdbdbd" }}>Trees Planted</Typography>
          </Box>
        </Box>

        {/* Blocks Found */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <ViewInArIcon sx={{ color: "#90ee90", fontSize: isMobile ? 23 : 30 }} />
          <Box>
            <Typography variant={isMobile ? "body2" : "h6"} sx={{ color: "#fff", fontWeight: "bold" }}>
              {totalBlocks}
            </Typography>
            <Typography variant="caption" sx={{ color: "#bdbdbd" }}>Blocks Found</Typography>
          </Box>
        </Box>

        {/* CO₂ Saved */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Co2Icon sx={{ color: "#90ee90", fontSize: isMobile ? 26 : 33 }} />
          <Box>
            <Typography variant={isMobile ? "body2" : "h6"} sx={{ color: "#fff", fontWeight: "bold" }}>
              {co2Saved}
            </Typography>
            <Typography variant="caption" sx={{ color: "#bdbdbd" }}>KG CO₂ Saved</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CountdownTrees;
