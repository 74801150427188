import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// ✅ **Esports-Style Card Container**
const OverwatchCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(135deg, #10131A 30%, #1E2A38 100%)", // Dark sci-fi gradient
  color: "white",
  borderRadius: "12px",
  boxShadow: "0px 0px 15px rgba(0, 255, 255, 0.3)", // Subtle neon glow
  padding: "16px",
  textAlign: "center",
  border: "1px solid rgba(0, 255, 255, 0.2)", // **Neon border**
  "&:hover": {
    boxShadow: "0px 0px 20px rgba(0, 255, 255, 0.6)", // **Stronger neon glow on hover**
  },
}));

// ✅ **Neon-Glowing Table Container**
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: "#181b23", // **Dark futuristic background**
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 255, 255, 0.15)", // Subtle outer glow
  maxHeight: "350px", // ✅ Keeps list compact
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 255, 255, 0.5)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#1b1f23",
  },
}));

// ✅ **Applies gradient on larger screens, solid color on mobile**
const StyledTableHead = styled(TableHead)(({ theme }) => ({
  boxShadow: "0px 0px 10px rgba(255, 255, 255, 0.1)", 
  [theme.breakpoints.up("sm")]: {
    background: "linear-gradient(90deg, #00E5FF 0%, #FFCC00 100%)", // ✅ Gradient for desktop
  },
  [theme.breakpoints.down("sm")]: {
    backgroundColor: "#00E5FF", // ✅ Solid color for mobile
  },
}));

// ✅ **Futuristic Table Cell Styling**
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#FFFFFF",
  borderBottom: "1px solid rgba(255, 255, 255, 0.15)", // Transparent divider
  textAlign: "center",
  fontSize: "12px",
  padding: "6px 10px",
  transition: "background 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)", // Subtle hover effect
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "13px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "14px",
  },
}));

// ✅ **Header Cells (Black Text)**
const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: "nowrap", // Prevent text from wrapping
  overflow: "hidden",
  fontSize: "10px",
  textTransform: "uppercase",
  color: "#10131A !important", // ✅ **Forces BLACK text**
  borderBottom: "2px solid rgba(0, 255, 255, 0.4)", // ✅ **Neon bottom border**
  textAlign: "center",
  padding: "3px 6px",
  "&:hover": {
    backgroundColor: "rgba(0, 255, 255, 0.2)", // ✅ **Subtle hover effect**
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "9px",
    fontWeight: "bold",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "10px",
    fontWeight: "bold",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  [theme.breakpoints.up("xxl")]: {
    fontSize: "18px",
    fontWeight: "bold",
  },
}));

// ✅ **Striped Rows with Neon Hover Effect**
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": { backgroundColor: "#22262e" }, // Dark gray
  "&:nth-of-type(even)": { backgroundColor: "#2b303a" }, // Slightly lighter gray
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.07)", // Neon hover effect
  },
}));

const MinerList = ({ walletAddress, resetTrigger, type = "pplns" }) => {
  const [miners, setMiners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [networkHashrate, setNetworkHashrate] = useState(null);
  const [blockReward, setBlockReward] = useState(null);
  const [isReset, setIsReset] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const BLOCKS_PER_DAY = 86400; // Sedracoin has 1 block per second = 86,400 per day

  // 🔹 **Dynamically set API URLs based on type**
  const MINER_API_URL = type === "solo"
    ? "https://sedrapool.com/api/pools/sdr-solo/miners/"
    : "https://sedrapool.com/api/pools/sdr/miners/";

  const NETWORK_API_URL = type === "solo"
    ? "https://sedrapool.com/api/pools/sdr-solo"
    : "https://sedrapool.com/api/pools/sdr";

  useEffect(() => {
    const storedReward = localStorage.getItem("blockReward");
    const storedHashrate = localStorage.getItem("networkHashrate");

    if (storedReward) {
      setBlockReward(parseFloat(storedReward.replace(/,/g, ""))); // Load cached reward
    }
    if (storedHashrate) {
      setNetworkHashrate(parseFloat(storedHashrate)); // Load cached hashrate
    }
  }, []);

  useEffect(() => {
    if (!walletAddress) return;

    setIsReset(false);
    fetchNetworkData().then(() => fetchMinerData(walletAddress));

    const interval = setInterval(() => {
      fetchNetworkData().then(() => fetchMinerData(walletAddress));
    }, 600000);

    return () => clearInterval(interval);
  }, [walletAddress, type]); // ✅ Re-fetch if `type` changes

  useEffect(() => {
    setMiners([]);
    setIsReset(true);
  }, [resetTrigger]);

  const fetchNetworkData = async () => {
    try {
      const response = await fetch(NETWORK_API_URL);
      if (!response.ok) throw new Error("Failed to fetch network data");

      const data = await response.json();

      if (data.pool?.networkStats?.networkHashrate) {
        const netHashrate = data.pool.networkStats.networkHashrate / 1e12;
        setNetworkHashrate(netHashrate);
        localStorage.setItem("networkHashrate", netHashrate);
      }

      if (data.pool?.networkStats?.reward) {
        const blockRewardVal = data.pool.networkStats.reward;
        localStorage.setItem("blockReward", blockRewardVal);
        setBlockReward(blockRewardVal);
      }
    } catch (err) {
      console.error("⚠️ Error fetching network data:", err.message);
    }
  };

  const fetchMinerData = async (address) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${MINER_API_URL}${address}`);
      if (!response.ok) throw new Error("Failed to fetch miner data");

      const data = await response.json();

      let walletEffort = data.minerEffort
        ? (Math.round(data.minerEffort * 1000) / 10)
        : null;

      let minerList = [];
      if (data.performance?.workers) {
        minerList = Object.entries(data.performance.workers).map(([name, stats]) => {
          const hashrateTHs = stats.hashrate ? stats.hashrate / 1e12 : 0;
          const minerEffort = stats.effort !== undefined
            ? (Math.round(stats.effort * 1000) / 10)
            : walletEffort;

          return {
            name,
            hashrate: hashrateTHs ? `${hashrateTHs.toFixed(2)} TH/s` : "-",
            rawHashrateTH: hashrateTHs,
            effort: minerEffort !== null ? `${minerEffort.toFixed(1)}%` : "-",
          };
        });
      }

      setMiners(minerList);
    } catch (err) {
      setError(err.message);
      setMiners([]);
    } finally {
      setLoading(false);
    }
  };

  const getEstimatedEarnings = (minerHashrate) => {
    if (!networkHashrate || !blockReward || !minerHashrate || isReset) {
      return "Loading...";
    }

    const blockRewardCorrected = blockReward / 1000;
    const minerShare = minerHashrate / networkHashrate;
    const estimatedEarnings = minerShare * blockRewardCorrected * BLOCKS_PER_DAY;

    return `${Math.round(estimatedEarnings).toLocaleString()} SDR/day`;
  };

  return (
    isMobile ? (
      <Box>
        <CardContent>
          <Typography 
            variant="subtitle1" 
            gutterBottom 
            sx={{ 
              fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
              fontFamily: "'Russo One', sans-serif",
              textTransform: "uppercase",
              textAlign: "left"
            }}
          >
            List of Miners Working for You
          </Typography>
          {loading ? (
            <CircularProgress size={24} />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <StyledTableContainer component={Paper}>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableHeaderCell>Name</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Hashrate</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Latest Effort</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Estimated Daily Earnings</StyledTableHeaderCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {isReset ? (
                    <StyledTableRow>
                      <StyledTableCell>-</StyledTableCell>
                      <StyledTableCell>-</StyledTableCell>
                      <StyledTableCell>-</StyledTableCell>
                      <StyledTableCell>-</StyledTableCell>
                    </StyledTableRow>
                  ) : miners.length > 0 ? (
                    miners.map((miner, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>{miner.name?.trim() || "-"}</StyledTableCell>
                        <StyledTableCell>{miner.hashrate?.trim() || "-"}</StyledTableCell>
                        <StyledTableCell>{miner.effort?.trim() || "-"}</StyledTableCell>
                        <StyledTableCell>{getEstimatedEarnings(miner.rawHashrateTH) || "-"}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={4} style={{ textAlign: "center" }}>
                        No miners found
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </StyledTableContainer>
          )}
        </CardContent>
      </Box>
    ) : (
      <OverwatchCard>
        <CardContent>
          <Typography 
            variant="subtitle1" 
            gutterBottom 
            sx={{ 
              fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "20px" },
              fontFamily: "'Russo One', sans-serif",
              textTransform: "uppercase",
              textAlign: "left"
            }}
          >
            List of Miners Working for You
          </Typography>
          {loading ? (
            <CircularProgress size={24} />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <StyledTableContainer component={Paper}>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableHeaderCell>Name</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Hashrate</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Latest Effort</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Estimated Daily Earnings</StyledTableHeaderCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {isReset ? (
                    <StyledTableRow>
                      <StyledTableCell>-</StyledTableCell>
                      <StyledTableCell>-</StyledTableCell>
                      <StyledTableCell>-</StyledTableCell>
                      <StyledTableCell>-</StyledTableCell>
                    </StyledTableRow>
                  ) : miners.length > 0 ? (
                    miners.map((miner, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>{miner.name?.trim() || "-"}</StyledTableCell>
                        <StyledTableCell>{miner.hashrate?.trim() || "-"}</StyledTableCell>
                        <StyledTableCell>{miner.effort?.trim() || "-"}</StyledTableCell>
                        <StyledTableCell>{getEstimatedEarnings(miner.rawHashrateTH) || "-"}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={4} style={{ textAlign: "center" }}>
                        No miners found
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </StyledTableContainer>
          )}
        </CardContent>
      </OverwatchCard>
    )
  );
};

export default MinerList;