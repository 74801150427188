import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,     // Mobile
      sm: 600,   // Tablets
      md: 900,   // Laptops
      lg: 1200,  // Desktops
      xl: 1536,  // Large Screens
      xxl: 2560, // 🔥 Ultra-Wide Screens (Custom Breakpoint)
    },
  },
  palette: {
    mode: "dark",
    background: {
      default: "#0A0D14",  // **Deep futuristic dark background**
      paper: "#181B23",    // **Slightly lighter for contrast**
    },
    primary: {
      main: "#00E5FF",     // **Neon cyan for futuristic highlights**
      contrastText: "#ffffff", // **Ensures readability on buttons**
    },
    secondary: {
      main: "#FFCC00",     // **Golden yellow (Overwatch theme)**
    },
    text: {
      primary: "#ffffff",  // **Main text color**
      secondary: "#FFCC00", // **Highlight text (Overwatch gold)**
      disabled: "rgba(255, 255, 255, 0.5)", // **Dimmed disabled text**
    },
    action: {
      hover: "rgba(0, 255, 255, 0.1)", // **Subtle hover effect**
    },
  },
  typography: {
    fontFamily: `"Oxanium", "Arial", sans-serif`, // **Esports-style font**
    h1: {
      fontSize: "2rem",
      fontWeight: "bold",
      letterSpacing: "1px",
      color: "#00E5FF",
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: "bold",
      color: "#FFFFFF",
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#FFCC00",
    },
    body1: {
      fontSize: "1rem",
      color: "#FFFFFF",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          background: "linear-gradient(135deg, #10131A 30%, #1E2A38 100%)", // **Futuristic gradient**
          borderRadius: "12px",
          boxShadow: "0px 0px 12px rgba(0, 255, 255, 0.15)",
          border: "1px solid rgba(0, 255, 255, 0.2)", // **Neon border effect**
          transition: "transform 0.25s ease-in-out, box-shadow 0.4s ease-in-out",
          "&:hover": {
            boxShadow: "0px 0px 25px rgba(0, 255, 255, 0.5)", // **Stronger neon glow**
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "uppercase",
          borderRadius: "8px",
          transition: "all 0.3s",
          "&:hover": {
            transform: "scale(1.08)",
            boxShadow: "0px 0px 15px rgba(0, 255, 255, 0.5)",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#222C38",
          borderRadius: "8px",
          "& .MuiInputBase-input": {
            color: "#FFFFFF",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 255, 255, 0.3)",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00E5FF",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00E5FF",
          },
        },
      },
    },
  },
});

export default theme;
